import { objectToQueryString } from '@buzzfeed/bf-utils/lib/query-string';
import { API_PROXY_URL } from '@/constants';
import { timedFetch, postJSON, putJSON } from './_base';

const PROXY_SUFFIX = '/api/proxy/tasty';
const ENDPOINT_PREFIX = `${API_PROXY_URL}${PROXY_SUFFIX}`;
const SORT_METHODS = {
  latest: 'created_at:desc'
};

class TastyApi {
  fetchCompilationBySlug(compilationSlug) {
    return timedFetch(`${ENDPOINT_PREFIX}/compilation/${compilationSlug}`);
  }

  fetchRecipeById(recipeId) {
    return timedFetch(`${ENDPOINT_PREFIX}/recipe/${recipeId}`);
  }

  fetchRecipeBySlug(recipeSlug) {
    return timedFetch(`${ENDPOINT_PREFIX}/recipe/${recipeSlug}`);
  }

  fetchRecipeTips(recipeId, sortMethod, from) {
    return timedFetch(`${ENDPOINT_PREFIX}/recipe/${recipeId}/tips?sort=${sortMethod}&from=${from}`);
  }

  fetchSuggestions(searchTerm) {
    // desired behavior is no suggestions when search term is empty - Can return this immediately
    if (!searchTerm) {
      return [];
    }
    return timedFetch(`${ENDPOINT_PREFIX}/suggest?prefix=${searchTerm}`);
  }

  fetchRecentFoodFeed({ size = 20, from = 0, includeFeatured = false, sponsoredPreview = false, credit_type = 'all' }) {
    return timedFetch(`${ENDPOINT_PREFIX}/food/recent?size=${size}&from=${from}&featured=${includeFeatured}&sponsoredPreview=${sponsoredPreview}&credit_type=${credit_type}`);
  }

  fetchRelatedRecipes(recipeId, count = 9) {
    return timedFetch(`${ENDPOINT_PREFIX}/recipe/${recipeId}/related/${count}`);
  }

  fetchTrendingRecipes({ recipeId, count, includeFeatured = false }) {
    const size = count || 6;
    let endpoint = `${ENDPOINT_PREFIX}/recipes/trending?size=${size}&featured=${includeFeatured}`;
    if (recipeId) {
      endpoint += `&recipe_id=${recipeId}`;
    }
    return timedFetch(endpoint);
  }

  fetchTastyItemsByTags(tags) {
    return timedFetch(`${ENDPOINT_PREFIX}/search/tags?tag_query=${tags.join(', ')}`);
  }

  fetchNavConfig() {
    return timedFetch(`${ENDPOINT_PREFIX}/config/nav`);
  }

  fetchIngredients() {
    return timedFetch(`${ENDPOINT_PREFIX}/config/ingredients`);
  }

  fetchSearchFeed({ size = 20, from = 0, searchTerms = [], tagFilters = [], sortMethod = '' }) {
    const formattedSearchTerms = Array.isArray(searchTerms) ? searchTerms.join(',') : searchTerms;
    const formattedTagFilters = Array.isArray(tagFilters) ? tagFilters.join(',') : tagFilters;

    let query = `${ENDPOINT_PREFIX}/search-feed?size=${size}&from=${from}`;
    if (formattedSearchTerms) {
      query += `&search_terms=${encodeURIComponent(formattedSearchTerms)}`;
    }
    if (formattedTagFilters) {
      query += `&tag_filters=${encodeURIComponent(formattedTagFilters)}`;
    }
    if (sortMethod in SORT_METHODS) {
      query += `&sort=${SORT_METHODS[sortMethod]}`;
    }

    return timedFetch(query);
  }

  fetchBrand(brand) {
    return timedFetch(`${ENDPOINT_PREFIX}/brand${objectToQueryString(brand)}`);
  }

  fetchFeaturePage(slug) {
    return timedFetch(`${ENDPOINT_PREFIX}/feature-page?slug=${slug}`);
  }

  fetchActiveFeatureCTA() {
    return timedFetch(`${ENDPOINT_PREFIX}/active-feature-cta`);
  }

  fetchCarousels({ timezone, carouselItems = 4 }) {
    return timedFetch(`${ENDPOINT_PREFIX}/carousels?timezone=${timezone}&carouselItems=${carouselItems}`);
  }

  fetchFeedPage({ from, size, slug, type }) {
    return timedFetch(`${ENDPOINT_PREFIX}/feed-page?from=${from}&size=${size}&slug=${slug}&type=${type}`);
  }

  fetchBrandFeed({ slug, size = 10, from = 0 }) {
    return timedFetch(`${ENDPOINT_PREFIX}/brand-feed/${slug}?size=${size}&from=${from}`);
  }
  
  fetchTagBreadcrumbsByTagIds({ tag_ids }) {
    return timedFetch(`${ENDPOINT_PREFIX}/tag_breadcrumbs?tag_ids=${tag_ids}`);
  }

  fetchTagNeighbors({ tag_id }) {
    return timedFetch(`${ENDPOINT_PREFIX}/tag_neighbors/${tag_id}`);
  }

  fetchCordialContact( { email }) {
    return timedFetch(`${ENDPOINT_PREFIX}/cordial/contacts?email=${email}`);
  }

  postCordialContact( { email, subscribed, unsubscribed }) {
    return postJSON(`${ENDPOINT_PREFIX}/cordial/contacts`, { email, subscribed, unsubscribed });
  }

  putCordialContact( { email, subscribed, unsubscribed }) {
    return putJSON(`${ENDPOINT_PREFIX}/cordial/contacts`, { email, subscribed, unsubscribed });
  }

  postCordialEmailVerify(email) {
    return postJSON(`${ENDPOINT_PREFIX}/cordial/verify`, { email });
  }

  fetchCordialContactByLookupKey(lookupKey) {
    return timedFetch(`${ENDPOINT_PREFIX}/cordial/verify/${lookupKey}`, {
      method: 'GET',
    })
  }
}

export default new TastyApi();
