import {
  trackClientAddressability,
  trackClientContentAction,
  trackClientExperimentActive,
  trackClientExternalLink,
  trackClientInternalLink,
  trackClientPageview,
  trackClientPerformance,
  trackClientVideoView,
} from './tracking/client-event-tracking';
import cookies from '@buzzfeed/bf-utils/lib/cookies';
import storage from '@buzzfeed/bf-utils/lib/storage';
import { urlQueryToObject } from '@buzzfeed/bf-utils/lib/query-string';

class Tracking {
  trackPageview({ pageInfo }) {
    // CET
    trackClientPageview(pageInfo);
  }

  trackAddressability({ pageInfo }) {
    const hem = cookies.get('hem');
    const user = cookies.get('bf2-b_info');
    const queryObject = urlQueryToObject(window.location.search);

    let addressableSource = [];
    if (queryObject['email_hash'] || storage.sessionGet('newsletterAddressable')) {
      storage.sessionSet({ key: 'newsletterAddressable', value: 'true' });
      addressableSource.push('newsletter');
    }
    if (user) {
      addressableSource.push('auth');
    }

    trackClientAddressability(pageInfo, {
      is_addressable: !!hem,
      addressable_source: addressableSource,
      addressable_partner: []
    });
  }

  /**
   * Handles CET events for content actions
   */
  trackContentAction({ pageInfo, eventInfo }) {
    // set some sane defaults for subunit, since not applicable in all cases
    const {
      position_in_subunit = null,
      position_in_unit = 0,
      subunit_name = '',
      subunit_type = '',
    } = eventInfo;

    trackClientContentAction(
      pageInfo,
      {
        ...eventInfo,
        position_in_subunit,
        position_in_unit,
        subunit_name,
        subunit_type,
      }
    );
  }

  trackExperimentsActive({ pageInfo, experiments }) {
    if (Array.isArray(experiments) && experiments.length > 0) {
      const experiment_id = []; // CET - concatenate series of strings based on each experiment
      experiments.forEach((experiment) => {
        const { id, name, variantId, variantName, version } = experiment;
        experiment_id.push([
          name,
          id,
          version,
          variantName,
          variantId,
        ].join('|'));
      });

      trackClientExperimentActive(
        pageInfo,
        {
          experiment_id,
        }
      );
    }
  }

  /**
   * Handles CET for external link events
   */
  trackExternalLink({ pageInfo, eventInfo }) {
    // set some sane defaults for subunit, since not applicable in all cases
    const {
      position_in_subunit = null,
      position_in_unit = 0,
      subunit_name = '',
      subunit_type = '',
    } = eventInfo;
    trackClientExternalLink(
      pageInfo,
      {
        ...eventInfo,
        position_in_subunit,
        position_in_unit,
        subunit_name,
        subunit_type,
      }
    );
  }

  /**
   * Form related events (e.g. UGC upload, newsletter signup)
   *  For actual submit (user hits 'submit'), track in CET
   *  For success or errors as result of submittions, as they are not
   *    actually client user events, we only track via
   * Current main use cases are UGC uploads and newsletter signups
   */
  trackFormEvent({ pageInfo, eventInfo }) {
    /**
     * status: 'submit', 'error', or 'success'
     * form: type of form, e.g. 'upload' (UGC), 'signup/email' (newsletter)
     */

    if (eventInfo.status === 'submit') {
      // Only send CET for submit events
      const {
        action_type,
        action_value,
        item_name,
        item_type = 'button',
        position_in_subunit = null,
        position_in_unit = 0,
        subunit_name = '',
        subunit_type = '',
        unit_name,
        unit_type,
      } = eventInfo;

      trackClientContentAction(
        pageInfo,
        {
          unit_type,
          unit_name,
          position_in_unit,
          subunit_type,
          subunit_name,
          position_in_subunit,
          item_type,
          item_name,
          action_type,
          action_value,
        }
      );
    }
  }

  /**
   * Handles CET & GA events for internal clicks
   */
  trackInternalLink({ pageInfo, eventInfo }) {
    const {
      data_source_name = '',
      data_source_algorithm = [],
      data_source_algorithm_version = [],
      item_name,
      item_type,
      position_in_subunit = null,
      position_in_unit = 0,
      subunit_name = '',
      subunit_type = '',
      target_content_id,
      target_content_type,
      unit_name,
      unit_type,
    } = eventInfo;
    trackClientInternalLink(pageInfo, {
      data_source_name,
      data_source_algorithm,
      data_source_algorithm_version,
      item_name,
      item_type,
      position_in_subunit: position_in_subunit,
      position_in_unit,
      subunit_name: subunit_name || '',
      subunit_type: subunit_type || '',
      target_content_id,
      target_content_type,
      unit_name,
      unit_type,
    });
  }

  /**
   * Handles CET 3 second video views
   */
  trackVideoView({ pageInfo, eventInfo }) {
    const {
      item_name,
      position_in_unit,
      position_in_subunit,
      subunit_name,
      subunit_type,
      unit_name,
      unit_type,
    } = eventInfo;
    trackClientVideoView(
      pageInfo,
      {
        item_name,
        item_type: 'video',
        position_in_unit,
        position_in_subunit,
        subunit_name,
        subunit_type,
        unit_name,
        unit_type,
      }
    );
  }

  trackPerformance(pageInfo) {
    trackClientPerformance(pageInfo);
  }
}

const tracking = new Tracking();

export default tracking;
