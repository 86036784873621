import stickyRegistry from '@buzzfeed/bf-utils/lib/sticky-registry';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import SvgIcon from '../../svg/SvgIcon/index';
import TrackableLink from '../../TrackableLink';
import { useJsonStorage } from '../../../hooks/useJsonStorage';

import MobileSubmenu from '../MobileSubmenu';
import SearchBar from '../SearchBar';
import SecondaryNav from '../SecondaryNav';
import DesktopSubmenu from '../submenu/DesktopSubmenu';

import { ABeagleContext, AnalyticsContext, ResizeContext, RouteContext } from '@/context';
import tracking from '@/services/tracking';
import { isServer } from '@/utils/misc-utils';

import { withErrorBoundary } from '@buzzfeed/react-components';
import { captureException } from '@sentry/nextjs';
import { useMyCart } from '../../OnlineGrocery/MyCartContext';


let FocusTrap;
if (typeof window !== 'undefined') {
  FocusTrap = require('focus-trap-react');
}

let ABeagleFlipper = () => null;

const TastyLogo = () => (
  <li className="nav__logo-container" key="tasty-logo">
    <TrackableLink
      ariaLabel="Tasty.co Home"
      className="nav__logo"
      linkTracking={{
        item_name: 'logo',
        item_type: 'image',
        target_content_id: 'home',
        target_content_type: 'feed',
        unit_name: 'main',
        unit_type: 'nav'
      }}
      url="/"
    >
      <SvgIcon
        aria-hidden="true"
        id="tasty-logo"
        height="100%"
        width="100%"
      />
    </TrackableLink>
  </li>
);

const MyCartNavItem = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [seenTooltip, setSeenTooltip] = useJsonStorage('a2b-promo-tooltip');
  const pageInfo = useContext(AnalyticsContext);
  const myCart = useMyCart();
  const animatedElRef = useRef(null);
  const [svgTitle, setSvgTitle] = useState('cart-not-selected');

  useEffect(() => {
    if (!seenTooltip) {
      setShowTooltip(true);
      setSeenTooltip(true);
    }
  }, []);

  const toggleTooltip = () => {
    tracking.trackContentAction({
      eventInfo: {
        action_type: 'hide',
        action_value: 'hide_promotion',
        item_name: 'mycart_promotion',
        item_type:'button',
        unit_name: 'main',
        unit_type: 'nav',
      },
      pageInfo,
    });
    setShowTooltip(false);
  };

  useEffect(() => {
    if (animatedElRef.current) {
      // trigger reflow to replay the animation
      animatedElRef.current.style.animation = 'none';
      animatedElRef.current.offsetHeight;
      animatedElRef.current.style.animation = null;
    }

    if (pageInfo?.page == 'mycart') {
      setSvgTitle('cart-selected');
    }
  }, [myCart.totalProducts]);

  return (
    <li className="nav__primary-menu-mycart list-unstyled" key="mycart">
      <TrackableLink
        ariaLabel="Your Cart"
        className="nav__primary-menu-mycart-link"
        linkTracking={{
          item_name: 'mycart',
          item_type: 'button',
          target_content_id: 'mycart',
          target_content_type: 'mycart',
          unit_name: 'main',
          unit_type: 'nav'
        }}
        url="/mycart"
      >
        <SvgIcon
          aria-hidden="true"
          id={svgTitle}
          width="28"
          height="28"
        />
        {myCart.totalProducts > 0 && (
          <span className="nav__primary-menu-mycart-items" ref={animatedElRef}>
            <span>{myCart.totalProducts}</span>
          </span>
        )}
      </TrackableLink>
      {showTooltip && (
        <div className="nav__primary-menu-mycart-tooltip">
          <p>The all-new Cart!</p>
          <div className="xs-text-5">
            Build a bag of Tasty recipes then choose how you want to get your
            groceries with Walmart.
          </div>
          <button
            className="share-button share-button--print share-button--round share-button--branded"
            onClick={toggleTooltip}
          >
            Got it
          </button>
        </div>
      )}
    </li>
  );
};

const NavCompact = () => {
  return (
    <>
      <a href="#content" className="nav__skip-to-content link-tasty">Skip to Content</a>
      <header className="nav">
        <nav
          aria-label="Primary menu"
          className="nav__contents"
        >
          <div className="nav__primary-menu">
            <ul
              aria-label="Primary navigation bar"
              className="nav__primary-menu-bar list-unstyled"
            >
              <TastyLogo />
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

const Nav = (props) => {
  const { canonicalUrl, navConfig, isCompact } = props;

  const headerRef = useRef(null);
  const primaryNavRefs = useRef(navConfig.primary.map(() => createRef()));
  const mobileNavToggleRef = useRef(null);

  const [menuToggled, setMenuToggled] = useState('');

  const { experiments } = useContext(ABeagleContext);
  const { pagePath, queryParams } = useContext(RouteContext);
  const { isMobile } = useContext(ResizeContext);
  const pageInfo = useContext(AnalyticsContext);

  const escCloseMenu = (event) => {
    /* esc key */
    if (event.keyCode === 27) {
      // close any open menu
      setMenuToggled('');
    }
  };

  const closeSubmenu = () => {
    setMenuToggled('');
  };

  /**
   * adding the header to the global sticky registry so all other sticky
   *  elements on the page (e.g. sidebar, Spotlight ad, inline ads)
   *  can correctly determine their top position
   */
  useEffect(() => {
    document.addEventListener('keydown', escCloseMenu);
    if (!headerRef.current) {
      return () => { }; // just for consistent return
    }
    const stickyHeader = headerRef.current.querySelector('.nav__primary-menu');

    if (!isMobile) {
      stickyRegistry.add(stickyHeader, { priority: 'high' });
    } else {
      stickyRegistry.remove(stickyHeader);
    }
    return () => {
      document.removeEventListener('keydown', escCloseMenu);
      stickyRegistry.remove(stickyHeader);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * During client side nav, when page doesn't fully reload, want to close
   *  any open submenus.
   */
  useEffect(() => {
    setMenuToggled('');
  }, [pagePath]);

  // a/b test debugger helper
  if (
    !isServer() &&
    'abdebug' in queryParams
  ) {
    /* istanbul ignore next */
    ABeagleFlipper = dynamic(
      () =>
        import(
          /* webpackChunkName: "abeagle-flipper" */ '@buzzfeed/react-components/dist/module/lib/components/ABeagleFlipper'
        ),
      { ssr: false }
    );
  }

  //functions
  const toggleNavItem = (item) => {
    // Toggle display
    const updatedMenuToggled = item === menuToggled ? '' : item;
    setMenuToggled(updatedMenuToggled);
    // Tracking: offset position by 1 due to logo
    const position = item === 'mobile' ? 1 : navConfig.primary.findIndex((category) => category.key === item) + 1;
    tracking.trackContentAction({
      eventInfo: {
        action_type: updatedMenuToggled === '' ? 'hide' : 'show',
        action_value: `hidden_${item}`,
        item_name: item === 'mobile' ? 'menu_icon' : item,
        item_type: item === 'mobile' ? 'button' : 'text',
        position_in_unit: position,
        unit_name: 'main',
        unit_type: 'nav',
      },
      pageInfo,
    });
  };

  //components
  const PrimaryNavItem = ((item, index) => (
    <li className={`nav__primary-menu-item ${index === 2 ? 'nav__primary-menu-item--last' : ''} list-unstyled`} key={item.key}>
      <button
        className={`nav__primary-menu-button button--clear ${menuToggled === item.key ? 'nav__primary-menu-button--active' : ''}`}
        onClick={() => toggleNavItem(item.key)}
        ref={primaryNavRefs.current[index]}
        value={menuToggled}
      >
        <span className="nav__primary-menu-button-text">
          {item.title}
        </span>
        <SvgIcon
          aria-hidden="true"
          className="nav__primary-menu-item-icon"
          height="100%"
          id="arrow-down"
          width="100%"
        />
      </button>
    </li>)
  );

  const desktopSubmenus = navConfig.primary.map((submenu) => {
    const toggled = submenu.key === menuToggled;
    // react-trap-focus library requires the surrounding div to fully register contents
    const baseSubmenu = (
      <div key={submenu.key}>
        <DesktopSubmenu submenu={submenu} toggled={toggled} closeSubmenu={closeSubmenu} />
      </div>
    );
    const focusTrapSubmenu = toggled ?
      <FocusTrap
        focusTrapOptions={{ clickOutsideDeactivates: true, preventScroll: true }}
        key={submenu.key}
      >
        {baseSubmenu}
      </FocusTrap> : baseSubmenu;
    return focusTrapSubmenu;
  });

  const onPrimaryBarClick = (e) => {
    // check if the click was on anyone of the buttons.  If it wasn't, then close the menu
    if (primaryNavRefs && primaryNavRefs.current && mobileNavToggleRef && mobileNavToggleRef.current) {
      const clickOnPrimaryItem = primaryNavRefs.current.some((ref) => ref.current.contains(e.target));
      if (!clickOnPrimaryItem && !mobileNavToggleRef.current.contains(e.target)) {
        setMenuToggled('');
      }
    }
  };

  if (isCompact) {
    return <NavCompact />;
  }

  return (
    <>
      <a href="#content" className="nav__skip-to-content link-tasty">Skip to Content</a>
      <ABeagleFlipper className="abFlipper" experiments={experiments} />
      <header className={`nav ${menuToggled === 'mobile' ? 'nav-mobile-menu__open' : ''}`}>
        <nav
          aria-label="Primary menu"
          className="nav__contents"
          ref={headerRef}
        >
          <div className="nav__primary-menu">
            {/* This enables mouse users to click outside the submenu to close, however, ESC is also
                setup for keyboard users */}
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
            <ul
              aria-label="Primary navigation bar"
              className="nav__primary-menu-bar list-unstyled"
              onClick={onPrimaryBarClick}
            >
              <TastyLogo />
              {navConfig.primary.map(PrimaryNavItem)}
              <li className="nav__primary-menu-search nav__primary-menu-search--desktop list-unstyled" key="search-bar">
                <SearchBar canonicalUrl={canonicalUrl} headerRef={headerRef} display="desktop" />
              </li>
              <MyCartNavItem />
              <li className="nav__submenu-toggle list-unstyled">
                <button
                  aria-label="Open submenu"
                  className={`nav__submenu-button nav__submenu-button--open ${menuToggled === 'mobile' ? 'xs-hide' : 'xs-flex'} button--clear`}
                  ref={mobileNavToggleRef}
                  onClick={() => toggleNavItem('mobile')}
                  value={menuToggled}
                >
                  <SvgIcon
                    aria-hidden="true"
                    className="nav__submenu-button-icon"
                    id="hamburger"
                  />
                </button>
              </li>
            </ul>
          </div>
          {/* Desktop Submenu - hidden with styling on mobile */}
          {desktopSubmenus}
          {(menuToggled !== '' && menuToggled !== 'mobile')
            && <div aria-hidden="true" className="nav__desktop-submenus-background" onClick={() => toggleNavItem(menuToggled)} />
          }
          <MobileSubmenu
            canonicalUrl={canonicalUrl}
            navConfig={navConfig}
            menuToggled={menuToggled}
            toggleNavItem={toggleNavItem}
            mobileNavToggleRef={mobileNavToggleRef}
          />
        </nav>
      </header>
      {menuToggled !== 'mobile' && <SecondaryNav menuToggled={menuToggled} secondaryConfig={navConfig.secondary} />}
    </>
  );
};

Nav.propTypes = {
  canonicalUrl: PropTypes.string.isRequired,
  navConfig: PropTypes.object.isRequired,
};

export default withErrorBoundary(Nav, {
  onError: captureException
});
