import { useBreakpoint } from '@buzzfeed/react-components';
import { isServer } from '@/utils/misc-utils';

export const useSolidBreakpoint = () => {
  const isSm = useBreakpoint('(min-width:40rem)');
  const isMd = useBreakpoint('(min-width:52rem)');
  const isLg = useBreakpoint('(min-width:64rem)');

  if (isServer()) {
    return null;
  }

  let breakpoint = 'xs';
  if (isLg) {
    breakpoint = 'lg';
  } else if (isMd) {
    breakpoint = 'md';
  } else if (isSm) {
    breakpoint = 'sm';
  }
  return breakpoint;
};
