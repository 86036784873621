import { AnalyticsContext } from '@/context';
import tracking from '@/services/tracking';
import { attachClientImpressionHandler } from '@/services/tracking/client-event-tracking';
import ClientLink from '../ClientLink';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';

const determineNavigation = (url) => {
  if (
    url.startsWith('/recipe') ||
    url.startsWith('/compilation') ||
    url.startsWith('/feature') ||
    url.startsWith('/buy')
  ) {
    const [ , type] = url.split('/');
    return {
      as: url,
      href: `/${type}/[slug]`,
    };
  } else if (
    url.startsWith('/topic') ||
    (url.startsWith('/ingredient') && url !== '/ingredient')
  ) {
    return {
      as: url,
      href: '/[feed]/[slug]',
    };
  } else if (['/', '/ingredient', '/submit-recipe', '/recent'].includes(url)) {
    return {
      as: url,
      href: url,
    };
  } else if (url.startsWith('/article')) {
    return {
      as: url,
      href: '/article/[author]/[slug]',
    };
  }
  return {};
};

/**
 * A link (internal or external) that is trackable, which includes an impression event
 * The visual appearance may look more like a button (will depend on what gets sent as
 *    classes and as "children"), but it acts like a link from a tracking/behavioral
 *    standpoint
 * @param {String|Array|Object}  children
 *                                  - content to display inside the link.  May be just a string or
 *                                    may be an array or object of React content
 * @param {String}  className       - optional set of classes to add to the top level link
 * @param {Function} onBlur         - optional function to call when link loses focus
 * @param {Boolean} openInNewWindow - optional flag to indicate link should open in a new window
 * @param {String}  url             - url that the link should go to  (may be relative or absolute path)
 * @param {String}  linkTracking    - tracking fields for the link
 *                                    Should at minimum include the following"
 *                                        item_name
 *                                        item_type
 *                                        target_content_id (internal and external links)
 *                                        target_content_type (internal and external links)
 *                                        target_content_url (if external link only)
 *                                        unit_name
 *                                        unit_type
 *
 *                                    If not included, these fields will have defaults
 *                                        data_source_name (defaults to '')
 *                                        data_source_algorithm (defaults to [])
 *                                        data_source_algorithm_version (defaults to [])
 *                                        position_in_subunit (defaults to null)
 *                                        position_in_unit (defaults to 0)
 *                                        subunit_name (defaults to '')
 *                                        subunit_type (defaults to '')
 */
const TrackableLink = ({
  ariaLabel = '',
  children,
  className = '',
  linkTracking,
  onBlur,
  openInNewWindow = false,
  url,
  nofollow = false,
  tabIndex,
  onClick = () => {},
  isAffiliate = false,
}) => {
  const ref = useRef(null);
  const pageInfo = useContext(AnalyticsContext);

  const eventInfo = {
    data_source_name: linkTracking.data_source_name || '',
    data_source_algorithm: linkTracking.data_source_algorithm || [],
    data_source_algorithm_version: linkTracking.data_source_algorithm_version || [],
    item_name: linkTracking.item_name,
    item_type: linkTracking.item_type,
    position_in_subunit: 'position_in_subunit' in linkTracking ? linkTracking.position_in_subunit : null,
    position_in_unit: 'position_in_unit' in linkTracking ? linkTracking.position_in_unit : 0,
    subunit_name: linkTracking.subunit_name || '',
    subunit_type: linkTracking.subunit_type || '',
    unit_type: linkTracking.unit_type,
    unit_name: linkTracking.unit_name,
  };

  const onLinkClick = (e) => {
    onClick(e);
    // CET & GA
    if (linkTracking.target_content_url) {
      tracking.trackExternalLink({
        eventInfo: {
          ...eventInfo,
          target_content_url: linkTracking.target_content_url,
        },
        pageInfo,
      });
    } else {
      tracking.trackInternalLink({
        eventInfo: {
          ...eventInfo,
          target_content_id: linkTracking.target_content_id,
          target_content_type: linkTracking.target_content_type,
        },
        pageInfo,
      });
    }
  };

  useEffect(() => {
    if (ref && ref.current) {
      // if an external link and not otherwise provided in tracking info, set target_content_id/type appropriately
      const target_content_id = (linkTracking.target_content_url && !linkTracking.target_content_id) ?
        linkTracking.target_content_url : linkTracking.target_content_id;
      const target_content_type = (linkTracking.target_content_url && !linkTracking.target_content_type) ?
        'url' : linkTracking.target_content_type;
      attachClientImpressionHandler(
        ref.current,
        pageInfo,
        {
          ...eventInfo,
          target_content_id,
          target_content_type,
        }
      );
    }
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

  let additionalAttributes = {};


  if (isAffiliate) {
    additionalAttributes['data-affiliate'] = true;
    openInNewWindow = true;
  }

  if (openInNewWindow) {
    additionalAttributes.target = '_blank';
    const internalLink = url.startsWith('/');
    additionalAttributes['aria-describedby'] = `new-window-${internalLink ? 'internal' : 'external'}`;
    if (!internalLink) {
      additionalAttributes.rel = 'noopener noreferrer';
    }
  }

  if (ariaLabel) {
    additionalAttributes['aria-label'] = ariaLabel;
  }

  if (nofollow) {
    additionalAttributes['rel'] = `nofollow ${additionalAttributes.rel || ''}`;
  }

  if (tabIndex !== undefined) {
    additionalAttributes['tabIndex'] = tabIndex;
  }

  if (!children) {
    return null;
  }

  // base link
  const link = (
    <a
      className={className}
      href={url}
      onBlur={onBlur ? onBlur : null}
      onClick={onLinkClick}
      ref={ref}
      {...additionalAttributes}
    >
      {children}
    </a>
  );

  // for certain page types, if on non-AMP can also do client side nav
  const clientNav = determineNavigation(url);
  if (clientNav.as && clientNav.href) {
    return (
      <ClientLink
        as={clientNav.as}
        href={clientNav.href}
      >
        {link}
      </ClientLink>
    );
  }

  return link;
};

TrackableLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  link: PropTypes.object,
  url: PropTypes.string,
  nofollow: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TrackableLink;
