const { settings } = require('@buzzfeed/rig');
const { TASTY_URL } = require('@/constants');

export const validateSlug = (slug) => {
  return slug && !!slug.match(/^[\w-]+\/?$/);
};

const getPageSurrogateKeys = ({ pageType, slug, id, article }) => {
  if (!pageType) {
    return [];
  }
  const keys = [`page=${pageType}`];
  if (id) {
    keys.push(`${pageType}_id=${id}`);
  }
  if (slug) {
    keys.push(`${pageType}_slug=${slug}`);
  }
  if (pageType === 'article' && article) {
    keys.push(`buzz${article.id}`, `user-${article.username}`);
  }
  return keys;
};

const getMaxAge = (pageProps, statusCode) => {
  return (statusCode === 200 && 'maxAge' in pageProps) ? pageProps.maxAge : 60;
};

const getCacheControl = ({ statusCode, pageProps }) => {
  const CLUSTER = settings.get('cluster');
  const SERVICE = settings.get('service');
  const RELEASE = settings.get('rig_image_version');
  const surrogateKeys = [
    `${SERVICE}-${CLUSTER}`,
    `cluster=${CLUSTER}`,
    `version=${RELEASE.toLowerCase()}`,
    `service=${SERVICE}`,
    ...getPageSurrogateKeys(pageProps),
  ];
  return {
    'Expires': '0',
    'Pragma': 'no-cache',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Surrogate-Control': `max-age=${getMaxAge(pageProps, statusCode)}`,
    'Surrogate-Key': surrogateKeys.join(' '),
  };
};

/**
 * The document-policy header is a mechanism that allows developers to set certain rules and
 * policies for a given site. The rules can change default browser behaviour, block certain features
 * or set limits on resource usage.
 * @see https://wicg.github.io/document-policy/
 *
 * For example, to enable use of the new JavaScript Self-Profiling API, a document policy of
 * `js-profiling` is required or the API will be blocked.
 * @see https://wicg.github.io/js-self-profiling/#document-policy
 *
 * @param {Array} options.policies
 * @returns {Object}
 */
const getDocumentPolicy = ({ policies = [] } = {}) => {
  return policies.length
    ? {
      'Document-Policy': policies.join(', '),
    }
    : {};
};

export const getResponseHeaders = (pageProps, { statusCode }) => {
  const responseHeaders = {
    'Content-Type': 'text/html; charset=utf-8',
  };
  // Do not send any caching instructions for the error response
  // Such responses rely on the ability of the CDN to serve stale content for a page
  if (statusCode >= 500) {
    return responseHeaders;
  }

  const cacheHeaders = getCacheControl({ statusCode, pageProps });

  const documentPolicyHeader = getDocumentPolicy({
    policies: [
      'js-profiling', // https://wicg.github.io/js-self-profiling/#document-policy
    ]
  });

  return {
    Vary: 'X-BF-GDPR, X-BF-CCPA',
    ...cacheHeaders,
    ...documentPolicyHeader,
    ...responseHeaders,
  };
};

export const getUrlOrigin = (req) => {
  const CLUSTER = settings.get('cluster');
  const hostUrl = req.headers.host;
  const match = hostUrl.match(/tasty-pager--[\w-]+/);
  return CLUSTER === 'stage' && match
    ? `https://${match[0]}.${settings.get('SINGLE_SIGNON_DOMAIN_NAME')}`
    : TASTY_URL;
};


export const getQueryFromUrl = (url) => {
  const [, ...queryStrings] = url ? url.split('?') : [];
  const queryString = queryStrings.join('?');
  const query = {};

  for (let [key, value] of new URLSearchParams(queryString).entries()) {
    query[key] = value;
  }

  return query;
};
