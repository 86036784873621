import React from 'react';
import PropTypes from 'prop-types';
import TrackableLink from '../../../TrackableLink';
import SubmenuCategory from '../SubmenuCategory';
import Shop from '../../../Shop';
import SvgIcon from '../../../svg/SvgIcon';


const DesktopSubMenu = ({ submenu, toggled, closeSubmenu }) => {
  return (
    <div
      aria-modal="true"
      aria-labelledby={`${submenu.key}-heading`}
      role="dialog"
      id={`${submenu.key}-submenu`}
      key={submenu.key}
      className={`nav__desktop-submenu ${toggled ? 'nav__desktop-submenu--open' : ''}`}
    >

      <div className="nav__desktop-submenu-content">
        <div className="nav__submenu-close-button--wrapper xs-flex-order-2">
          <button
            aria-label="Close submenu"
            className="nav__submenu-close-button"
            onClick={closeSubmenu}>
            <SvgIcon
              aria-hidden="true"
              className="svg-1"
              id="x"
            />
            <span className="screenreader-only">Close</span>
          </button>
        </div>
        <h2 id={`${submenu.key}-heading`} className="screenreader-only">{submenu.key} submenu</h2>
        {submenu.key !== 'shop' &&
          submenu.categories.map((category, index) =>
            <SubmenuCategory
              key={index}
              submenuKey={submenu.key}
              category={category}
              categoryIndex={index}
            />
          )
        }
        {submenu.key === 'shop' &&
          <Shop
            content={submenu}
            trackingFields={{
              unit_name: 'hidden_shop',
              unit_type: 'nav',
            }}
            variant="nav"
          />}
      </div>
      {submenu.key === 'recipes' &&
        <div className="nav__recipes-submit-container">
          <div className="nav__recipes-submit-content">
            <h2 className="nav__recipes-submit-heading">Submit a recipe to Tasty!</h2>
            <p>Have a recipe of your own to share?
              <TrackableLink
                className="nav__recipes-submit-link link-tasty"
                linkTracking={{
                  item_name: 'recipe_submission',
                  item_type: 'text',
                  position_in_unit: 6,
                  target_content_id: 'recipe_submission',
                  target_content_type: 'form',
                  unit_name: 'hidden_recipes',
                  unit_type: 'nav'
                }}
                openInNewWindow={true}
                url="/submit-recipe"
              >
                Submit your recipe
              </TrackableLink>
            </p>
          </div>
        </div>
      }
    </div>
  );
};

DesktopSubMenu.propTypes = {
  submenu: PropTypes.object,
  toggled: PropTypes.bool
};

export default DesktopSubMenu;
