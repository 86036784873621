import React, { useContext, useEffect, useState } from 'react';
import { ABeagleContext } from '@/context';
import { admiralScript } from '@buzzfeed/bf-utils';
import { isOn } from '@buzzfeed/react-components/lib/utils/abeagle';



const AdmiralScript = () => {
  const { experiments } = useContext(ABeagleContext);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!experiments.loaded || experiments.stale) {
      return;
    }
    const isEnabled = isOn(experiments, 'RT-1042-Admiral-script-on-Tasty');

    if (isEnabled && !isLoaded) {
      setIsLoaded(true);
      admiralScript.init('tasty');
    }
  }, [experiments.loaded, experiments.stale]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <></>
  );
}

export default AdmiralScript;
