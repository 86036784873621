import React from 'react';
import { capitalize } from '@/utils/misc-utils';
import TrackableLink from '../../TrackableLink';
import SvgIcon from '../../svg/SvgIcon';

const socialResources = [
  {
    type: 'facebook',
    url: 'https://www.facebook.com/buzzfeedtasty/',
  },
  {
    type: 'twitter',
    url: 'https://twitter.com/tasty',
  },
  {
    type: 'instagram',
    url: 'https://www.instagram.com/buzzfeedtasty',
  },
  {
    type: 'youtube',
    url: 'https://www.youtube.com/buzzfeedtasty',
  }
];

const SocialButtons = ({
  position_in_unit = 0,
  unit_name = 'footer',
  unit_type = 'bottom',
}) => {
  const buttons = socialResources.map((socialResource) => {
    const { type, url } = socialResource;
    const linkTracking = {
      item_type: 'button',
      item_name: url,
      position_in_unit,
      target_content_id: url,
      target_content_type: 'url',
      target_content_url: url,
      unit_name,
      unit_type,
    };
    return (
      <li key={type}>
        <TrackableLink
          ariaLabel={`Go to Tasty on ${capitalize(type)}`}
          className="social-button"
          linkTracking={linkTracking}
          url={url}
        >
          <SvgIcon aria-hidden={true} id={type} />
        </TrackableLink>
      </li>
    );
  });
  return (
    <div className="social-buttons">
      <div className="social-buttons__heading">Follow Tasty</div>
      <ul className="social-buttons__list">
        {buttons}
      </ul>
    </div>
  );
};

export default SocialButtons;
