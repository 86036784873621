import { useContext, useEffect } from 'react';
import { api, FEATURE_FLAG } from '@buzzfeed/consent';
import { isOn } from '@buzzfeed/react-components/lib/utils/abeagle';
import { ABeagleContext } from '@/context';

/**
 * Serves two purposes:
 * 1) explicitly initializes the CMP (which is necessary since a CMP stub is defined in <head>,
 * otherwise calls to ` __tcfapi` made by 3rd parties that rely on consent will be pending forever)
 * 2) manages whether Quantcast Choice or a dummy fallback will be used as a CMP
 * based on a feature flag state (feature flag may be turned off temporarily in case of emergency,
 * in which case the fallback will be used until it's back on)
 */
export default function ConsentManagement() {
  const { experiments } = useContext(ABeagleContext);

  useEffect(() => {
    if (!experiments.loaded || experiments.stale) {
      return;
    }
    const flagState = isOn(experiments, FEATURE_FLAG.name);
    api.configure({ useFallback: !flagState });
  }, [experiments.loaded, experiments.stale]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    api.init();
  }, []);

  return null;
}
