import React, { useContext } from 'react';
import { RouteContext } from '@/context';
import { CLIENT_NAV_ENABLED } from '@/constants';
import Link from 'next/link';

const ClientLink = ({
  href,
  as,
  children
}) => {
  const { queryParams } = useContext(RouteContext);
  const useClientNav = (queryParams?.client_nav_enabled === 'true' || (CLIENT_NAV_ENABLED && queryParams?.client_nav_enabled !== 'false')) && !!href && !!as;
  if (useClientNav) {
    return <Link href={href} as={as} prefetch={false}>{children}</Link>
  } else {
    return children;
  }
};

export default ClientLink;
