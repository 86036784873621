import React from 'react';
import PropTypes from 'prop-types';
import TrackableLink from '../../../TrackableLink';

const SubmenuItem = ({ item, categoryIndex, categoryKey, itemIndex, submenuKey }) => {
  const linkTracking = {
    item_name: item.target_content_id,
    item_type: 'text',
    position_in_subunit: itemIndex,
    position_in_unit: categoryIndex,
    subunit_name: categoryKey,
    subunit_type: 'package',
    target_content_id: item.target_content_id,
    target_content_type: item.target_content_type,
    unit_name: `hidden_${submenuKey}`,
    unit_type: 'nav'
  };
  if (!item.is_internal_link) {
    /**
     * For external links, also include URL for external link events (in addition to
     *  id field for impressions)
     */
    linkTracking.target_content_url = item.target_content_id;
  }
  return (
    <li className="list-unstyled" key={`submenu-item-${itemIndex}`}>
      <TrackableLink
        className="nav__submenu-item"
        linkTracking={linkTracking}
        url={item.loc}
      >
        {item.title}
      </TrackableLink>
    </li>
  );
};

const SubmenuCategory = ({ category, categoryIndex, submenuKey }) => {
  return (
    <section className="nav__submenu-category-wrapper">
      <h3 className="nav__submenu-category-heading">{category.title}</h3>
      <ul className="nav__submenu-category" key={`submenu-column-${categoryIndex}`}>
        {category.items.map((item, index) =>
          <SubmenuItem
            key={index}
            categoryKey={category.key}
            categoryIndex={categoryIndex}
            item={item}
            itemIndex={index}
            submenuKey={submenuKey}
          />
        )}
      </ul>
    </section>
  );
};

SubmenuCategory.propTypes = {
  category: PropTypes.object,
  categoryIndex: PropTypes.number,
  submenuKey: PropTypes.string,
};

export default SubmenuCategory;
