import { framework } from '@buzzfeed/consent';

/**
 * Variation on @buzzfeed/consent 'needsConsent' that returns the consent type
 *    (or null) instead of a boolean
 */
export const getConsentType = () => {
  if (framework.needsGDPRConsent()) { return 'gdpr'; }
  if (framework.needsCCPAConsent()) { return 'ccpa'; }
  return null;
};
