import { AdExBase, AdEx } from '@buzzfeed/adlib/dist/module/components/ex';
import AdAffiliatePixel from '@buzzfeed/adlib/dist/module/components/affiliate-pixel';
import AdAwareness from '@buzzfeed/adlib/dist/module/components/awareness';
import AdBigstory from '@buzzfeed/adlib/dist/module/components/bigstory';
import AdPromoInline from '@buzzfeed/adlib/dist/module/components/promo-inline';
import AdToolbar from '@buzzfeed/adlib/dist/module/components/toolbar';

export { default as ads } from '@buzzfeed/adlib/dist/module/core/ads';
export * as core from '@buzzfeed/adlib/dist/module/core';

export const components = {
  AdAffiliatePixel,
  AdAwareness,
  AdBigstory,
  AdEx,
  AdExBase,
  AdPromoInline,
  AdToolbar,
};

import AffiliatePixelTemplate from '@buzzfeed/adlib/dist/module/components/affiliate-pixel/index.svelte.js';
import AwarenessTemplate from '@buzzfeed/adlib/dist/module/components/awareness/index.svelte.js';
import AdBigstoryTemplate from '@buzzfeed/adlib/dist/module/components/bigstory/index.svelte';
import AdExTemplate from '@buzzfeed/adlib/dist/module/components/ex/index.svelte.js';
import AdPromoInlineTemplate from '@buzzfeed/adlib/dist/module/components/promo-inline/index.svelte.js';
import AdToolbarTemplate from '@buzzfeed/adlib/dist/module/components/toolbar/index.svelte.js';


export const templates = {
  AffiliatePixelTemplate,
  AwarenessTemplate,
  AdBigstoryTemplate,
  AdExTemplate,
  AdPromoInlineTemplate,
  AdToolbarTemplate,
};
