import React from 'react';
import PropTypes from 'prop-types';
import TrackableLink from '../TrackableLink';
import { CdnImage } from '@buzzfeed/react-components';
import composeRefs from '@seznam/compose-react-refs';
import { ABeagleContext } from '@/context';
import { useAmazonSubtagModifier } from '@buzzfeed/react-components/lib/hooks/useAmazonSubtagModifier';
import { useSkimlinksXcustModifier } from '@buzzfeed/react-components/lib/hooks/useSkimlinksXcustModifier';

const titles = {
  body: {
    cookbooks: 'Shop Tasty Cookbooks',
    cookware: 'Shop Tasty Cookware',
  },
  nav: {
    cookbooks: 'Latest Cookbooks',
    cookware: 'Featured Cookware',
  }
};

const ShopCategory = ({ category, trackingFields, variant }) => {
  const clickAmazonRef = useAmazonSubtagModifier({ ABeagleContext });
  const clickSkimlinksRef = useSkimlinksXcustModifier({
    // mock default buzz data
    buzz: {
      classification: {
        edition: '',
        section: ''
      },
      tags: [],
      flags: {
        nsfw: 0,
        sensitive: 0
      }
    }, ABeagleContext });
  return (
    <div className={`shop__category shop__category--${variant}`} ref={composeRefs(clickAmazonRef, clickSkimlinksRef)}>
      <div className="shop__category-heading">
        <h2 className={`shop__category-title xs-flex-grow-1 extra-bold ${variant === 'nav' ? 'xs-text-4' : 'xs-text-2'}`}>
          {titles[variant][category.key]}
        </h2>
        {/* desktop shop all link */}
        <TrackableLink
          className={`shop__shop-all-link link-tasty xs-text-5 ${variant === 'nav' ? '' : 'md-text-3'}`}
          isAffiliate={!!category?.is_affiliate}
          linkTracking={{
            item_name: category.shop_all,
            item_type: 'text',
            target_content_id: category.shop_all, // for impressions
            target_content_url: category.shop_all, // for external link
            ...trackingFields
          }}
          url={category.shop_all}
        >
          Shop all
        </TrackableLink>
      </div>
      <div className="shop__category-content">
        {category.items.map((item, index) =>
          <div key={index} className={`shop__category-item shop__category-item--${category.key}`}>
            <TrackableLink
              className={`shop__category-item-link shop__category-item-link--${category.key}`}
              isAffiliate={!!item?.is_affiliate}
              linkTracking={{
                item_name: item.loc,
                item_type: 'card',
                position_in_subunit: index,
                target_content_id: item.loc, // for impressions
                target_content_url: item.loc, // for external link
                ...trackingFields,
              }}
              url={item.loc}
            >
              <div className={`shop__category-item-image-wrapper shop__category-item-image-wrapper--${category.key}`}>
                <CdnImage
                  alt={item.image_alt}
                  className={`shop__category-item-image shop__category-item-image--${category.key}`}
                  lazy={true}
                  src={`${item.image}?resize=350:*`}
                />
              </div>
              <div className={`shop__category-item-title xs-text-5 sm-text-4 ${variant === 'nav' ? 'md-text-4' : 'md-text-3'}`}>
                {item.title}
              </div>
            </TrackableLink>
          </div>
        )}
      </div>
      {/* mobile shop all button */}
      <TrackableLink
        className="shop__shop-all-button button--tasty button--tasty-secondary"
        isAffiliate={!!category?.is_affiliate}
        linkTracking={{
          item_name: category.shop_all,
          item_type: 'button',
          target_content_id: category.shop_all, // for impressions
          target_content_url: category.shop_all, // for external link
          ...trackingFields
        }}
        url={category.shop_all}
      >
        Shop all {category.key}
      </TrackableLink>
    </div>
  );
};

const Shop = ({ content, positionOffset, trackingFields, variant }) => {
  return (
    <div className="shop">
      {content.categories.map((category, index) =>
        <ShopCategory
          category={category}
          key={category.key}
          trackingFields={{
            ...trackingFields,
            position_in_unit: positionOffset + index,
            subunit_name: category.key,
            subunit_type: 'package',
            target_content_type: 'url',
          }}
          variant={variant}
        />
      )}
    </div>
  );
};

Shop.propTypes = {
  content: PropTypes.object,
  positionOffset: PropTypes.number,
  trackingFields: PropTypes.object,
  variant: PropTypes.oneOf(['nav', 'body']),
};

Shop.defaultProps = {
  positionOffset: 0,
};

export default Shop;
