import React from 'react';
import PropTypes from 'prop-types';
import { ResizeContext } from '@/context';
import { useSolidBreakpoint } from '@/hooks/useSolidBreakpoint';
import { isMobile as isMobileLayout } from '@/utils/misc-utils';


export default function ResizeManager(props) {
  const breakpoint = useSolidBreakpoint();
  const value = {
    breakpoint,
    isMobile: isMobileLayout(breakpoint),
  };

  return (
    <ResizeContext.Provider value={value}>
      { props.children }
    </ResizeContext.Provider>
  );
}

ResizeManager.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};
