import fetch from 'isomorphic-unfetch';
import { parameterize } from '@/utils/misc-utils';

export class HttpError extends Error {
  constructor(response) {
    const message = `${response.status} for ${response.url}: ${response.statusText}`;
    super(message);

    this.name = 'HttpError';
    this.status = response.status;
    this.statusText = response.statusText;
    this.response = response;
  }
}

/* eslint-disable consistent-return */
export async function timedFetch(
  path,
  params
) {
  const url = params ? `${path}?${parameterize(params)}` : path;
  const response = await fetch(url, { credentials: 'include' });

  if (response.ok) {
    const json = await response.json();
    return json;
  } else {
    throw new HttpError(response);
  }
}

export async function postJSON(
  path,
  body
) {
  const response = await fetch(path, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
    credentials: 'include',
  });
  if (response.ok) {
    const json = await response.json();
    return json;
  } else {
    throw new HttpError(response);
  }
}

export async function putJSON(
  path,
  body
) {
  const response = await fetch(path, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
    credentials: 'include',
  });
  if (response.ok) {
    const json = await response.json();
    return json;
  } else {
    throw new HttpError(response);
  }
}


