import { ResizeContext } from '@/context';
import { withErrorBoundary } from '@buzzfeed/react-components';
import { captureException } from '@sentry/nextjs';
import React, { useContext, useEffect, useState } from 'react';
import Ad from './';

const eligiblePages = new Set([
  'article',
  'brand',
  'compilation',
  'feature',
  'mycart',
  'guides',
  'home',
  'ingredients', // ingredient landing page
  'ingredient',
  'latest',
  'recipe',
  'search',
  'tag',
  'topic',
]);

function AwarenessAd ({ page }) {
  let [className, setClassName] = useState('');
  const { isMobile } = useContext(ResizeContext);
  const [mounted, setMounted]= useState(false);
  const isVideoRecipePage = page.recipe?.non_video_recipe?.status === false;

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setClassName(isVideoRecipePage
        ? 'Ad--awareness--sticky-bottom-tasty'
        : 'Ad--awareness--sticky');
    }
  }, [isMobile]);

  if (
    !eligiblePages.has(page.pageType)
    || (page.recipe && (page.recipe.is_sponsored_recipe || page.recipe.brand_id))
  ) {
    return null;
  }

  return (
    <>
      {/* While waiting for mount, use placeholder to provide some min-height to avoid CLS shift*/}
      {
        !mounted &&
        <div className={isVideoRecipePage ? 'placeholderAd--video-recipe' : 'placeholderAd'}></div>
      }
      {
        mounted &&
        <Ad
          type={`awareness${page.pageType === 'article' ? '-bp' : '' }`}
          initiallyMounted={true}
          className={className}
        />
      }
    </>
  );
}
export default withErrorBoundary(AwarenessAd, {
  onError: captureException
});
