import React from 'react';
import PropTypes from 'prop-types';
import TrackableLink from '../../TrackableLink';
import {
  ADVERTISE_WITH_TASTY_URL,
  PRIVACY_POLICY_URL,
  USER_AGREEMENT_URL,
} from '@/constants';

const links = [
  {
    item_name: 'feedback',
    target_content_type: 'url',
    text: 'Send feedback',
    url: 'mailto:support@buzzfeed.com',
    openInNewWindow: false,
  },
  {
    item_name: 'ingredient_list',
    target_content_type: 'feed',
    text: 'Recipes by Ingredient',
    url: '/ingredient',
    openInNewWindow: false,
  },
  {
    item_name: 'topic_community',
    target_content_type: 'feed',
    text: 'Community Recipes',
    url: '/topic/community',
    openInNewWindow: false,
  },
  {
    item_name: 'privacy_policy',
    target_content_type: 'url',
    text: 'Privacy Policy',
    url: PRIVACY_POLICY_URL,
    openInNewWindow: true,
  },
  {
    item_name: 'user_agreement',
    target_content_type: 'url',
    text: 'User Agreement',
    url: USER_AGREEMENT_URL,
    openInNewWindow: true,
  },
  {
    item_name: 'accessibility',
    target_content_type: 'landing',
    text: 'Accessibility Statement',
    url: '/accessibility',
    openInNewWindow: false,
  },
  {
    item_name: 'tastyvalues',
    target_content_type: 'landing',
    text: 'Our Tasty Values',
    url: '/tastyvalues',
    openInNewWindow: false,
  },
  {
    item_name: 'advertise_with_tasty',
    target_content_type: 'url',
    text: 'Advertise with Tasty',
    url: ADVERTISE_WITH_TASTY_URL,
    openInNewWindow: true,
  }
];

const FooterLinks = ({ pageUrl }) => {
  return links.map((link) => {
    const { item_name, openInNewWindow, target_content_type, text, url } = link;
    const isExternalLink = target_content_type === 'url';
    const linkTracking = {
      item_type: 'text',
      item_name,
      position_in_unit: 0,
      target_content_id: isExternalLink ? url : item_name,
      target_content_type,
      unit_name: 'footer',
      unit_type: 'bottom',
    };
    if (isExternalLink) {
      linkTracking.target_content_url = url;
    }
    return (
      <li key={item_name}>
        <TrackableLink
          className="footer-link"
          linkTracking={linkTracking}
          openInNewWindow={openInNewWindow}
          url={
            item_name === 'feedback' ?
              `${url}?subject=Tasty.co User Support&body=%0A%0A----------------------- %0A%0ALink: ${encodeURIComponent(pageUrl)}` :
              url
          }
        >
          {text}
        </TrackableLink>
      </li>
    );
  });
};

FooterLinks.propTypes = {
  pageUrl: PropTypes.string.isRequired,
};

export default FooterLinks;
