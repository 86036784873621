import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useConsent } from '@buzzfeed/react-components';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { GOOGLE_RECAPTCHA_SITEKEY_V2 } from '@/constants';
import { AnalyticsContext, ResizeContext } from '@/context';
import {
  submitSucceeded,
  submitFailed,
  captchaValidated,
  submitStart,
  emailInputFocused,
} from './actions';
import tracking from '@/services/tracking';
import { getRefValue, isValidEmail } from '@/utils/misc-utils';
import { withErrorBoundary } from '@buzzfeed/react-components';
import { captureException } from '@sentry/nextjs';
import SubhubApi from '@/services/api/subhub';
import { trackCordialSignUp } from '@/services/tracking/cordial';


const subscribe = async (body) => {
  try {
    const response = await SubhubApi.postNewsletters(body);
    if (!response.ok) {
      return {
        error: response,
      };
    }
    return {
      response: { ok: response.ok },
    };
  } catch (error) {
    return { error };
  }
};

const validateEmail = (element) => {
  const email = element.value;

  if (email.trim() === '') {
    return {
      error: 'missing_email',
      message: 'Please enter an email address.'
    };
  } else if (!isValidEmail(email)) {
    return {
      error: 'invalid_email',
      message: 'Please enter a valid email address.'
    };
  }

  return { email };
};

const titles = {
  full: 'The Tasty Newsletter',
  inline: 'Get the Tasty Newsletter',
};

const subtitles = {
  full: 'Easy recipes and cooking hacks right to your inbox',
  inline: '',
};

/**
 * Newsletter component
 * @param {String} variant          - Identifies which variant to use (mostly determines visual changes)
 * @param {Object} trackingFields   - Tracking info that varies based on where the unit is used
 */
function Newsletter(props) {
  const { trackingFields, variant } = props;
  const newsletterId = `newsletter-${variant}`;
  const pageInfo = useContext(AnalyticsContext);
  const { isMobile } = useContext(ResizeContext);
  const recaptchaRef = useRef(null);
  const inputRef = useRef(null);
  const { consentValue, isConsentReady } = useConsent('tracking');
  const [state, dispatch] = useReducer(
    (prevState, action) => action(prevState),
    {}
  );

  const trackNewsletterEvent = ({ status, label = null }) => {
    const {
      gaDimension,
      position_in_subunit = null,
      position_in_unit,
      subunit_name = '',
      subunit_type = '',
      unit_name,
      unit_type
    } = trackingFields;
    /**
      * Note: currently assumes this unit is always at bottom of page (above the footer).
      * If this changes, update unit_name/dimensions appropriately
      */
    tracking.trackFormEvent({
      eventInfo: {
        // shared
        status,
        label,
        // GA
        category: 'NewsletterPromo',
        customDimensions: {
          dimension6: gaDimension,
        },
        form: 'signup/email',
        // CET
        action_type: 'signup',
        action_value: 'tastycookingclub',
        item_name: 'email',
        item_type: 'submission',
        position_in_subunit,
        position_in_unit,
        subunit_name,
        subunit_type,
        unit_name,
        unit_type,
      },
      pageInfo,
    });
  };

  // Effect for submitting form data to the API
  useEffect(() => {
    if (!state.submitting) {
      return;
    }

    (async function submitForm() {
      const { response, error } = await subscribe(state.formData);
      if (error || !response.ok) {
        /**
         * If there was an error with the call to newsletter API itself,
         * log that error, send a tracking event and set error state
         */
        console.error({ message: 'Error subscribing to newsletter', error });
        trackNewsletterEvent({ status: 'error', label: 'newsletter-api' });
        return dispatch(
          submitFailed({
            error: {
              message: 'Oops, Something went wrong. Try again later.',
            },
          })
        );
      }
      // newsletter signup succeeded, send tracking event and set success state
      trackNewsletterEvent({ status: 'success' });
      if (consentValue && isConsentReady) {
        trackCordialSignUp({
          email: state.formData.email,
          url: window.location.href || '',
          source: variant === 'inline' ? 'footer' : 'sidebar',
        });
      }
      return dispatch(submitSucceeded());
    })();
  }, [state.formData, state.submitting]); // eslint-disable-line react-hooks/exhaustive-deps

  // Effect for fetching captcha token when form is focused
  useEffect(() => {
    if (!state.focused) {
      return;
    }

    (async function dispatchCaptchaToken() {
      const recaptcha = getRefValue(recaptchaRef);
      const token = await recaptcha.executeAsync();
      recaptcha.reset(); // reset allows for subsequent submissions without error.
      dispatch(captchaValidated(token));
    })();
  }, [state.focused]);

  const handleInputFocus = () => {
    dispatch(emailInputFocused());
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (state.submitting) {
      return;
    }

    // reset success state with every new form submission
    state.success = false;

    // send submit tracking events, regardless of success or error
    trackNewsletterEvent({ status: 'submit' });

    // check for valid email input
    const { email, error, message } = validateEmail(inputRef.current);

    /**
      * For failed form validation dispatch an error, send tracking event,
      * and focus the control which had an error
      */
    if (error) {
      trackNewsletterEvent({ status: 'error', label: 'email-validation' });
      dispatch(submitFailed({ error: { message } }));
      inputRef.current.focus();
      return;
    }

    // reset focused state so that a new captcha is generated for the next form submission
    state.focused = false;

    dispatch(
      submitStart({
        subscriptions: ['buzzfeed_email_tasty'],
        brand: 'buzzfeed',
        'g-recaptcha-response': state.token,
        source: isMobile ? 'buzzfeed-mobileweb-hub' : 'buzzfeed_desktop_hub',
        email,
      })
    );
    return;
  };

  return (
    <div className={`newsletter newsletter--${variant}`}>
      <form
        className={`newsletter__form newsletter__form--${variant}`}
        id={newsletterId}
        target="_top"
      >
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={GOOGLE_RECAPTCHA_SITEKEY_V2}
        />
        {state.success ?
          <div className={`newsletter__success newsletter__success--${variant} xs-col-12 xs-mx-auto`}>
            <p className="newsletter__success-header extra-bold xs-mb1">
              Let’s get cooking!
            </p>
            <p className="newsletter__success-body">
              Stay tuned for your first newsletter!
            </p>
          </div> :
          <>
            {
              variant === 'full' ?
                <h2 className="newsletter__header newsletter__header--full extra-bold xs-text-2 xs-mx-auto md-p1">
                  {titles.full}
                </h2> :
                <h2 className={`newsletter__header newsletter__header--${variant} xs-mb1 xs-text-3 extra-bold`}>
                  {titles[variant]}
                </h2>
            }
            <div className={`newsletter__content xs-relative newsletter__content--${variant}`}>
              {
                subtitles[variant] &&
                <p className='newsletter__subtitle xs-text-4 xs-col-12 xs-mx-auto xs-mb2'>
                  {subtitles[variant]}
                </p>
              }
              <div className={`newsletter__input-wrapper newsletter__input-wrapper--${variant}`}>
                <label className="newsletter__input-label" htmlFor={`newsletter-email-${variant}`}>
                  <span className="newsletter__input-label-text">Email address</span> (required)
                </label>
                <input
                  autoComplete="email"
                  className={`newsletter__input newsletter__input--${variant} ${state.error && state.error.message ? 'newsletter__input--error' : ''}`}
                  onFocus={handleInputFocus}
                  id={`newsletter-email-${variant}`}
                  name="email"
                  placeholder="Your email address"
                  required
                  type="email"
                  ref={inputRef}
                />
                <button
                  className={`newsletter__submit newsletter__submit--${variant} button--tasty bold`}
                  disabled={state.submitting}
                  onClick={handleFormSubmit}
                  type="submit"
                  value="Sign up"
                >
                  Sign up
                </button>
                {(state.error && state.error.message) ?
                  <div
                    className={`newsletter__errors newsletter__errors--${variant}`}
                    key="newsletter-error"
                  >
                    {state.error.message}
                  </div> : null
                }
                {/*
                    This disclaimer is required if wanting to hide the sticky recaptcha button:
                      https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
                  */}
                <div className="newsletter__input-disclaimer" key="newsletter-disclaimer">
                  This site is protected by reCAPTCHA and the Google <a className="newsletter__input-disclaimer-link" href="https://policies.google.com/privacy">Privacy Policy</a> and <a className="newsletter__input-disclaimer-link" href="https://policies.google.com/terms">Terms of Service</a> apply.
                </div>
              </div>
            </div>
          </>}
      </form>
    </div>
  );
}

Newsletter.propTypes = {
  trackingFields: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['full', 'inline']).isRequired,
};

export default withErrorBoundary(Newsletter, {
  onError: captureException
});
