import { createClientEvent } from '@buzzfeed/client-event-tracking';
import {
  ab_test,
  addressability,
  content_action,
  external_link,
  impression,
  internal_link,
  pageview,
  time_spent,
  videoview,
} from '@buzzfeed/client-event-tracking/events';

import {
  createClientImpressionHandler,
  createClientTimeSpentHandler
} from '@buzzfeed/client-event-tracking/handlers';

import { trackPerformance } from '@buzzfeed/performance';
import { CLUSTER } from '@/constants';

const sendClientEvent = createClientEvent({
  amp_options: {
    // in order to include amp_id for connecting AMP and non-AMP events
    track_amp: true,
  },
  env: CLUSTER,
  source: 'web_tasty',
});

export const tastyCommonDataLayer = content => () => ({
  context_page_id: content.context_page_id,
  context_page_type: content.context_page_type,
  destination: 'tasty',
  page_edition: content.page_edition,
  /**
   * referrer will only have set value after client side navigation, so if no
   *  value set use document referrer
   */
  referrer_uri: content.referrer || document.referrer,
});

const createClientEventWithCommonData = (event_config) =>
  (content, ...layers) => {
    sendClientEvent(event_config, tastyCommonDataLayer(content), ...layers);
  };

export const trackClientAddressability = createClientEventWithCommonData(addressability);
export const trackClientPageview = createClientEventWithCommonData(pageview);
export const trackTimeSpent = createClientEventWithCommonData(time_spent);

export const trackClientContentAction = createClientEventWithCommonData(content_action);
export const trackClientExperimentActive = createClientEventWithCommonData(ab_test);
export const trackClientExternalLink = createClientEventWithCommonData(external_link);
export const trackClientInternalLink = createClientEventWithCommonData(internal_link);
export const trackClientVideoView = createClientEventWithCommonData(videoview);

export const trackClientImpression = createClientEventWithCommonData(impression);

export const attachClientImpressionHandler = createClientImpressionHandler(trackClientImpression);
export const attachClientTimeSpentHandler = createClientTimeSpentHandler(
  ({ layers }, ...additional_args) => {
    trackTimeSpent(...additional_args, ...layers);
  }
);

export const trackClientPerformance = (content) => {
  trackPerformance(sendClientEvent, { layers: [tastyCommonDataLayer(content)], sample_rate: 0.5 });
};
