import { FEATURE_FLAG as CONSENT_FEATURE_FLAG } from '@buzzfeed/consent';

export default [
  CONSENT_FEATURE_FLAG,
  {
    name: 'DT-516-tasty_ui-time-spent-metrics-test',
    variations: ['control', 'time_spent_tracked'],
    isEligible: () => true
  },
  {
    name: 'RT-1042-Admiral-script-on-Tasty',
    variations: ['control', 'on'],
    isEligible: () => true,
  },
];
