import React from 'react';
import TrackableLink from '../TrackableLink';
import androidAppBadge from '@/images/app_badge_android.png';
import iosAppBadge from '@/images/app_badge_ios.png';

const urls = {
  ios: 'https://apps.apple.com/us/app/tasty/id1217456898?itsct=apps_box_badge&itscg=30200',
  android: 'https://play.google.com/store/apps/details?id=com.buzzfeed.tasty&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
};
const altText = {
  ios: 'Download on the App Store',
  android: 'Get it on Google Play',
};

const AppBadge = ({
  className = '',
  device = 'ios',
  trackingFields,
}) => {

  const url = urls[device];
  const { position_in_unit, unit_name, unit_type } = trackingFields;

  const linkTracking = {
    item_type: 'button',
    item_name: url,
    position_in_unit,
    unit_name,
    unit_type,
    target_content_id: url,
    target_content_type: 'url',
    target_content_url: url,
  };

  return (
    <TrackableLink
      className={`app-badge app-badge--${device} ${className}`}
      linkTracking={linkTracking}
      url={url}
    >
      <img
        alt={altText[device]}
        className={`app-badge__image app-badge__image--${device}`}
        src={device === 'android' ? androidAppBadge : iosAppBadge}
      />
    </TrackableLink>
  );
};

export default AppBadge;
