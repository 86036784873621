import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { getExperimentVariant } from '@buzzfeed/react-components/lib/utils/abeagle';
import AdsProvider from '@buzzfeed/adlib/dist/module/bindings/react/components/AdsContext';
import useABeagleAsync from '@/hooks/useABeagleAsync';
import { getPageContext } from '@/services/ad';

/* istanbul ignore next */
async function getPageTargeting({ page, abeagle }) {
  const experiments = await abeagle.experiments;
  const adsExperiments = [];
  const abtests = adsExperiments.map(([experiment, key]) => {
    const value = getExperimentVariant(experiments, experiment, {
      rejectErrors: false,
      defaultVariantIfUnbucketed: null,
    });
    return `${key || experiment}|${value}`;
  });
  // Additional flags for tasty specific experiments
  for (const key in experiments.eligible) {
    if (Object.prototype.hasOwnProperty.call(experiments.eligible, key)) {
      const variant = experiments.eligible[key].value;
      const [group, ticket] = key.split('-');
      if (group === 'TASTY') {
        const target = `TASTY-${ticket}|${variant}`;
        abtests.push(target);
      }
    }
  }
  const pageTargeting = {
    bfmono: 'tasty_ui', // always indicate tasty_ui as source,
    abtest: abtests,
  };
  let additionalTargeting = {};
  const { pageType } = page;
  if (pageType === 'recipe') {
    const { id, show, video_id } = page.recipe;
    additionalTargeting = {
      recipeid: `${id}`,
      show: show.name,
    };
    if (video_id) {
      additionalTargeting.vid = `${video_id}`;
    }
  }  else if (pageType === 'tag') {
    additionalTargeting.tag = page.slug;
  } else if (pageType === 'compilation') {
    additionalTargeting = {
      compilationid: `${page.compilation.id}`,
      show: page.compilation.show.name,
      vid: `${page.compilation.video_id}`,
    };
  } else if (['feature', 'ingredient', 'topic'].includes(pageType)) {
    additionalTargeting = {
      [pageType]: page.slug,
    };
  } else if(page.pageType === 'article' && page.article) {
    additionalTargeting.tasty_aid = page.article.id;
  }
  Object.assign(pageTargeting, additionalTargeting);
  return pageTargeting;
}

/* istanbul ignore next */
function Ads({ page, children }) {
  const abeagle = useABeagleAsync();

  const adsEnabled = page.pageType === 'recipe' ? !page.recipe.is_sponsored_recipe : true;

  const getPageContextBound = useCallback(() => {
    return getPageContext({
      page,
      abeagle,
    });
  }, [page.pagePath]); // eslint-disable-line react-hooks/exhaustive-deps

  const getPageTargetingBound = useCallback(
    () => getPageTargeting({ page, abeagle }),
    [page.pagePath] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return <AdsProvider
    pageId={page.pagePath}
    adsEnabled={adsEnabled}
    getPageContext={getPageContextBound}
    getPageTargeting={getPageTargetingBound}
  >
    {children}
  </AdsProvider>;
}

Ads.propTypes = {
  page: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default Ads;
