import { API_PROXY_URL } from '@/constants';
import { postJSON } from './_base';

const PROXY_SUFFIX = '/api/proxy/subhub';

class SubhubApi {
  async postNewsletters(props) {
    const endpoint = `${API_PROXY_URL}${PROXY_SUFFIX}/newsletters`;

    try {
      const response = await postJSON(endpoint, props);
      return response;

    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default new SubhubApi();
