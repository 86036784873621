import { programmaticSizes } from '@buzzfeed/adlib/dist/module/services/size/constants';
import sizeUtils from '@buzzfeed/adlib/dist/module/services/size/standalone';
import {
  components as adlibComponents,
  templates as adlibTemplates,
} from '../services/adlib';

function isInlineArticle(adConfig) {
  const { adPos } = adConfig;
  return /^promo-inline/.test(adPos) && adConfig.pageType === 'article';
}

export function getTemplate(adConfig) {
  const { adPos } = adConfig;
  if (adPos === 'promo1-bp' || isInlineArticle(adConfig)) {
    return adlibTemplates.AdPromoInlineTemplate;
  } else if (adPos.startsWith('awareness')) {
    return adlibTemplates.AwarenessTemplate;
  } else if (/pixel$/.test(adPos)) {
    return adlibTemplates.AffiliatePixelTemplate;
  } else if (/^(sidebar|bigstory)/.test(adPos)) {
    return adlibTemplates.AdBigstoryTemplate;
  } else if (/^(tb)/.test(adPos)) {
    return adlibTemplates.AdToolbarTemplate;
  }
  return adlibTemplates.AdExTemplate;
}

export function getComponent(adConfig) {
  const { adPos } = adConfig;
  if (adPos === 'promo1-bp' || isInlineArticle(adConfig)) {
    return adlibComponents.AdPromoInline;
  } else if (adPos.startsWith('awareness')) {
    return adlibComponents.AdAwareness;
  } else if (/pixel$/.test(adPos)) {
    return adlibComponents.AdAffiliatePixel;
  } else if (/^(sidebar|bigstory)/.test(adPos)) {
    return adlibComponents.AdBigstory;
  } else if (/^(tb)/.test(adPos)) {
    return adlibComponents.AdToolbar;
  }
  return adlibComponents.AdEx;
}

export function getMinSizes(adConfig) {
  const sizes = sizeUtils.getProgrammatic(programmaticSizes, adConfig.size);
  const minHeight = Math.min(Infinity, ...sizes.map(([, h]) => h));
  const minWidth = Math.min(Infinity, ...sizes.map(([w]) => w));
  return [minWidth, minHeight];
}

export function getMaxSizes(adConfig) {
  const sizes = sizeUtils.getProgrammatic(programmaticSizes, adConfig.size);
  const maxHeight = Math.max(0, ...sizes.map(([, h]) => h));
  const maxWidth = Math.max(0, ...sizes.map(([w]) => w));
  return [maxWidth, maxHeight];
}

export function getWireframeOptions(adConfig) {
  if (adConfig.adPos.startsWith('awareness')
    || (adConfig.adPos.includes('sidebar') && adConfig.pageType === 'article')) {
    return {};
  }
  const isInline = isInlineArticle(adConfig);
  /**
   * Use minimum width/height as wireframe size in most cases on most pages to avoid
   *  expanding and collapsing for CLS
   * Use except inline ads on article pages, where full width is needed in order
   *  to help filter out sizes that do not fit
   */
  const widthSizes = isInline ? getMaxSizes(adConfig) : getMinSizes(adConfig);
  const heightSizes = getMinSizes(adConfig);

  return {
    programmaticWireframes: true,
    wireframeWidth: widthSizes[0],
    wireframeHeight: heightSizes[1],
    wireframeClasses: defaultClasses =>
      `${defaultClasses} ${isInline ? 'ad-wireframe-wrapper--inline' : ''}`,
  };
}
