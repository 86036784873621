import React from 'react';
import PropTypes from 'prop-types';

/**
 * This component takes `id` as a required prop which references
 * the linked svg symbol. Available symbol definitions can be
 * found in the SvgSymbols component.
 */

/**
 * a11y best practice
 * Ensure there is a role="img" and aria-labelledby attributes to each tag,
 * then provide a <title> tag within the tag
 *  and give this <title> tag a unique id attribute.
 * Ensure the <title> is a direct descendant of the tag
 * and that the tag’s aria-labelledby attribute holds the <title> tag’s id value. */

const SvgIcon = ({id, title, ...props}) => {
  //If null, aria-labelledby will not appear in the dom
  const formattedId = title ? title.toLowerCase().split(' ').join('_') : null;
  return (
    <svg aria-labelledby={formattedId} {...props}>
      { title && <title id={formattedId}>{title}</title> }
      <use xmlnsXlink="https://www.w3.org/1999/xlink" xlinkHref={`#${id}`} />
    </svg>
  );
};

SvgIcon.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  'aria-hidden': PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  className: PropTypes.string,
  height: PropTypes.string,
  role: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
};

export default SvgIcon;

