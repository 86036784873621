import { cloneDeep, merge } from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from '@buzzfeed/react-components';
import Ad from '@buzzfeed/adlib/dist/module/bindings/react/components/Ad';
import { AdsContext } from '@buzzfeed/adlib/dist/module/bindings/react/contexts';
import { AnalyticsContext } from '@/context';
import { articleConfig, config } from '@/constants/ads';
import { ResizeContext, RouteContext } from '@/context';
import * as adUnitUtils from '@/utils/ad-unit';
import { captureException } from '@sentry/nextjs';
import tracking from '@/services/tracking';

/* istanbul ignore next */
function AdUnit(props) {
  const { pagePath, pageType } = useContext(RouteContext);
  const pageInfo = useContext(AnalyticsContext);
  const { breakpoint } = useContext(ResizeContext);
  const context = useContext(AdsContext);
  const [mounted, setMounted] = useState(props.initiallyMounted || false);

  const adConfig = useMemo(() => {
    // prefer different config for article pages, as breakpoint and sizes are different
    const cfg = pageType === 'article' && articleConfig[props.type] ?
      cloneDeep(articleConfig[props.type]) :
      cloneDeep(config[props.type]); // avoid mutating the shared config
    cfg.pageType = pageType;

    // check whether it matches the current breakpoint
    if ('supports' in cfg && !cfg.supports.find((bp) => bp === breakpoint)) {
      return null;
    }
    cfg.advertiserContext = false;
    cfg.targeting = merge(cfg.targeting, props.targeting || {});
    return cfg;
  }, [props.type, props.targeting, breakpoint]);

  // for sticky awareness on mobileweb
  const stickyManager = useRef({
    notify: (_, { callback }) => callback({ shouldStick: true }),
  });

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!context || !context.eventBus || !adConfig?.wid || context.status !== 'loaded') {
      return () => {};
    }

    const eventInfoData = {
      action_type: 'hide',
      action_value: 'ad',
      item_name: 'hide',
      item_type: 'button',
      unit_type: 'modal',
    };

    context.eventBus.on(`sticky-ad-user-dismiss-top:${adConfig.wid}`, () => {
      tracking.trackContentAction(
        {
          eventInfo: {
            ...eventInfoData,
            unit_name: 'top_ad',
          },
          pageInfo,
        }
      );
    });
    context.eventBus.on(`sticky-ad-user-dismiss-bottom:${adConfig.wid}`, () => {
      tracking.trackContentAction(
        {
          eventInfo: {
            ...eventInfoData,
            unit_name: 'bottom_ad',
          },
          pageInfo,
        }
      );
    });
  }, [context]);

  if (!mounted || !adConfig) {
    return null;
  }
  return <Ad
    adUnitUtils={adUnitUtils}
    config={adConfig}
    pageId={pagePath}
    stickyManager={stickyManager.current}
    className={props.className}
    customDisclosure={props.disclosure}
  />;
}

AdUnit.defaultProps = {
  className: '',
  disclosure: 'ADVERTISEMENT',
};

AdUnit.propTypes = {
  type: PropTypes.string.isRequired,
  targeting: PropTypes.object,
  className: PropTypes.string,
  disclosure: PropTypes.string,
};

export default withErrorBoundary(AdUnit, {
  onError: captureException
});
