export const parameterize = (query) => {
  return Object.entries(query)
    .filter(entry => (Array.isArray(entry[1]) ? entry[1].length : entry[1]))
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
};

export const pluralize = (unit, quantity, zeroIsPlural = true) => {
  let value = unit;
  if ((zeroIsPlural && quantity !== 1) || (!zeroIsPlural && quantity > 1)) {
    value += 's';
  }
  return value;
};

export const stripQueryParams = (path) => {
  // strip any query parameters
  return path.indexOf('?') > -1 ? path.substring(0, path.indexOf('?')) : path;
};

export const convertSecondsToISO8601 = (inputSeconds) => {
  /**
    * Follows guidelines from: https://en.wikipedia.org/wiki/ISO_8601#Durations
    *  where elements may be omitted if their value is zero, and lower elements
    *  may be omitted for reduced precision.
    * Leading zeros are not necessary
    * Limitation: Goes to days.  Does not roll over to months/days
    */
  let returnValue = 'PT0S';

  try {
    const date = new Date(null);
    date.setSeconds(inputSeconds);
    const isoString = date.toISOString();
    const oneDay = 60 * 60 * 24;
    const days = parseInt(inputSeconds / oneDay, 10);
    const parsedTime = isoString.substr(11, 8).split(':');
    const hours = parseInt(parsedTime[0], 10);
    const minutes = parseInt(parsedTime[1], 10);
    const seconds = parseInt(parsedTime[2], 10);

    if (days || hours || minutes || seconds) {
      let duration = 'P';

      if (days) {
        duration += `${days}D`;
      }
      if (hours || minutes || seconds) {
        duration += 'T';
      }
      if (hours) {
        duration += `${hours}H`;
      }
      if (minutes) {
        duration += `${minutes}M`;
      }
      if (seconds) {
        duration += `${seconds}S`;
      }
      returnValue = duration;
    }
    return returnValue;
  } catch (e) {
    return returnValue;
  }
};

export const isServer = () => typeof window === 'undefined';

// adapted from buzzblocks/js/services/solid
const SIZES = [
  { breakpoint: 'xs', rule: '(max-width:39.9rem)' },
  { breakpoint: 'sm', rule: '(min-width:40rem) and (max-width:51.9rem)' },
  { breakpoint: 'md', rule: '(min-width:52rem) and (max-width:63.9rem)' },
  { breakpoint: 'lg', rule: '(min-width:64rem)' },
];

export const getSolidBreakpoint = () => {
  if (isServer()) { return null; }
  for (const { breakpoint, rule } of SIZES) {
    if (window.matchMedia(`screen and ${rule}`).matches) {
      return breakpoint;
    }
  }
  return 'lg';
};

export const isMobile = (breakpoint) => ['xs', 'sm'].includes(breakpoint || getSolidBreakpoint());

/**
  * Returns whether device is an iOS device
  * @returns {Boolean}
  * Note: this technique might fail if Macs ever have touch screens; may need to revisit
  * Reference: https://stackoverflow.com/questions/57776001/how-to-detect-ipad-pro-as-ipad-using-javascript
 */
export const isIOSAgent = () => {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  }
  // for newer iPads
  return navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform);
};

/**
  * Returns whether browser is chrome on iOS
  * @returns {Boolean}
  * Note: this technique might fail if User Agents change.  Is not ideal, but best workaround
  *   able to find for now
  * Reference: https://stackoverflow.com/questions/13807810/ios-chrome-detection
 */
export const isIOSChrome = () => {
  return isIOSAgent() && /CriOS/i.test(navigator.userAgent);
};

export const timeAgo = (seconds) => {
  const secondsMap = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };
  const intervalName = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];

  let returnValue = '';
  intervalName.some((name) => {
    const interval = Math.floor(seconds / secondsMap[name]);
    if (interval >= 1 || name === 'second') {
      returnValue = `${interval} ${name}${interval > 1 ? 's' : ''}`;
      return true;
    } else {
      return false;
    }
  });
  return returnValue;
};

export const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.toLocaleString('default', { day: '2-digit' });
  const year = date.getFullYear();
  const formattedDate = month + ' ' + day + ', ' + year;
  return formattedDate;
};

/**
  * Gets cookie value by name.
  * @param {String} name - cookie name.
  * @param {String} defaultValue - value to return if cookie is not defined.
  * @returns {String} - value of the specified cookie or default.
 */
export const getCookie = (name, defaultValue = null) => {
  const regex = new RegExp(`\\b${name}=([^;]+)`); // https://regex101.com/r/5PbWpy/4/tests

  const match = document.cookie.match(regex);
  const value = match && match[1] ? match[1] : defaultValue;

  return value;
};

/**
 * Memoize a function call to prevent duplicate side effects.
 * Using JSON.stringify(arguments) for the cache key. This allows
 * a function call + arguments to be cached as a unique invocation
 * @param  {function} func
 * @todo Update the `once` utility
 */
export const memoize = (fn) => {
  const cache = new Map();

  return function() {
    const key = JSON.stringify(arguments);

    if (!cache.has(key)) {
      cache.set(key, fn.apply(this, arguments));
    }

    return cache.get(key);
  };
};

export const validateImageFile = (file) => {
  const maxSize = 10000000;

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      window.URL.revokeObjectURL(img.src);

      if (file.size > maxSize) {
        reject('Please upload a file smaller than 10MB.');
      }
      resolve();
    };
    img.src = window.URL.createObjectURL(file);
  });
};

export const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getToday = () => {
  const date = new Date();
  var month = (date.getMonth() + 1).toString();
  var day = (date.getDate()).toString();
  var year = (date.getFullYear()).toString();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return `${month}-${day}-${year}`;
};

/**
 * Takes the date and finds the timezone offset from UTC in hours, including
 *  a sign (+ or -) to indicate direction.  E.g. "-10" or "+05"
 * @param {Date} date: date object
 * @returns {String}
 */
export const getUTCOffset = (date) => {
  const hoursOffset = Math.floor(date.getTimezoneOffset() / 60);
  // if hoursOffset is less then 0, it means UTC is ahead, so add '+' to signify add this many hours
  return `${hoursOffset < 0 ? '+' : '-'}${Math.abs(hoursOffset) < 10 ? '0' : ''}${Math.abs(hoursOffset)}`;
};

export const isValidEmail = (email) => {
  const validEmailRegExpr = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return validEmailRegExpr.test(email);
};

export const getRefValue = (ref) => {
  if (ref) {
    return ref.current;
  }
  return null;
};

/**
 * Calculates total price, excludes unavailable ingredients
 * @param {Array} ingredients - list of ingredients
 * @returns {Number} - ingredients total price
 */
export const getTotalPrice = (ingredients) => {
  const totalPrice = ingredients.reduce((price, ingredient) => {
    if (ingredient.strategy === 'out_of_stock' || ingredient.strategy === 'no_match') {
      return price;
    }

    return price += ingredient.price * ingredient.quantity;
  }, 0);

  return totalPrice;
};
