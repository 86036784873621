// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { SENTRY_PUBLIC_DSN as dsn, RIG_DEPLOYMENT_TYPE } from './constants';
import ignoreErrors from './utils/sentry-ignore-errors';

Sentry.init({
  dsn,
  tracesSampleRate: 0,
  integrations(integrations) {
    return integrations.filter(
      integration => integration.name !== 'Breadcrumbs'
    );
  },
  initialScope: {
    tags: {"rig_deployment_type": RIG_DEPLOYMENT_TYPE},
  },
  ignoreErrors,
  environment: process.env.NODE_ENV,
  allowUrls: [
    /^https:\/\/www\.buzzfeed\.com\/static-assets/,
    /^https:\/\/tasty\.co\/static-assets/,
  ],
  sampleRate: 0.01,
  attachStacktrace: true,
});
