import storage from '@buzzfeed/bf-utils/lib/storage';
import { useState } from 'react';

export const useJsonStorage = (key) => {
  const [value, setValue] = useState(JSON.parse(storage.get(key)));

  const saveValue = newValue => {
    newValue = typeof newValue === 'undefined' ? null : newValue;
    setValue(newValue);
    storage.set({ key, value: JSON.stringify(newValue) });
  };

  return [value, saveValue];
}
