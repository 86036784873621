import React from 'react';
import PropTypes from 'prop-types';
import TrackableLink from '../../TrackableLink';
import SvgIcon from '../../svg/SvgIcon';

const SecondaryNav = (props) => {
  const { secondaryConfig } = props;

  const sharedTracking = {
    item_type: 'text',
    unit_name: 'secondary',
    unit_type: 'nav'
  };

  const shopMerchLink = 'https://shop.buzzfeed.com/collections/tasty?utm_source=TastyDotCo&utm_medium=Nav+Redirect&utm_campaign=Tasty22';

  const SecondaryNavItem = ((item, index) => {
    const linkTracking = {
      ...sharedTracking,
      item_name: item.target_content_id,
      position_in_unit: index,
      target_content_id: item.target_content_id,
      target_content_type: item.target_content_type,
    };
    if (!item.is_internal_link) {
      /**
       * For external links, also include URL for external link events (in addition to
       *  id field for impressions)
       */
      linkTracking.target_content_url = item.target_content_id;
    }
    return (
      <li className="list-unstyled" key={`secondary-nav-item-${index}`}>
        <TrackableLink
          className="secondary-nav__link"
          linkTracking={linkTracking}
          url={item.loc}
        >
          {item.loc === shopMerchLink && (
            <SvgIcon
              aria-hidden="true"
              id="shop-merch"
              width="14"
              height="14"
            />
          )}
          {item.title}
        </TrackableLink>
      </li>
    );
  });

  return (
    <nav aria-label="Secondary menu" className='secondary-nav'>
      {/* Mobile/SM breakpoints: show 2 links */}
      <ul className="secondary-nav__mobile">
        { secondaryConfig.mobile.map(SecondaryNavItem)}
      </ul>
      {/* Desktop: show 6 links */}
      <ul className="secondary-nav__desktop">
        { secondaryConfig.desktop.map(SecondaryNavItem)}
      </ul>
    </nav>
  );
};

SecondaryNav.propTypes = {
  secondaryConfig: PropTypes.object.isRequired
};

export default SecondaryNav;
