export const submitStart = formData => prevState => ({
  ...prevState,
  formData,
  submitting: true,
});

export const submitFailed = ({ error }) => prevState => ({
  ...prevState,
  submitting: false,
  error,
});

export const submitSucceeded = () => prevState => ({
  ...prevState,
  submitting: false,
  error: null,
  success: true,
});

export const emailInputFocused = () => prevState => ({
  ...prevState,
  focused: true,
});

export const captchaValidated = token => prevState => ({
  ...prevState,
  token,
});
