import { CLUSTER } from '@/constants';
import { CordialTracker } from '@buzzfeed/third-party-analytics';

let trackingInstance;

const crdl = () => {
  if (!trackingInstance) {
    trackingInstance = new CordialTracker({
      client: 'tasty',
      cluster: CLUSTER,
    });
  }
  return trackingInstance;
};

const getTrackingData = (pageData) => {
  const url = window?.location?.href || '';
  switch (pageData.pageType) {
    case 'home':
      return {
        eventType: 'page_browsed',
        eventData: {
          pagetype: 'homepage',
          url
        }
      };
    case 'topic':
      return {
        eventType: 'topic_browsed',
        eventData: {
          pagetype: 'topic',
          category: pageData.slug,
          url
        }
      };
    case 'article':
      return {
        eventType: 'article_browsed',
        eventData: {
          pagetype: 'article',
          author: pageData.analyticsInfo.author,
          category: pageData.article.category,
          name: pageData.analyticsInfo.title,
          url
        }
      };
    case 'recipe':
      const featured_in = pageData.recipe.featured_in
        ? `${pageData.canonicalUrl}/compilation/${pageData.recipe.featured_in.slug}`: null;
      return {
        eventType: 'recipe_browsed',
        eventData: {
          pagetype: 'recipe',
          page_id: pageData.id,
          category: 'recipes',
          name: pageData.analyticsInfo.title,
          featured_in,
          url
        }
      };
    case 'compilation':
      return {
        eventType: 'compilation_browsed',
        eventData: {
          pagetype: 'compilation',
          page_id: pageData.id,
          name: pageData.analyticsInfo.title,
          url
        }
      };
    case 'mycart':
      return {
        eventType: 'mycart_browsed',
        eventData: {
          pagetype: 'mycart',
          url
        }
      };
    case 'search':
      return {
        eventType: 'search',
        eventData: {
          pagetype: 'search_results',
          search_keywords: pageData.searchTerms.join(',') || '',
          url
        }
      };
  }
}

export async function trackCordialEvent({ eventType, eventData }) {
    const tracker = await crdl();
    tracker.trackEvent({ eventType, eventData });
}

export async function trackCordialPageView(pageData) {
  const trackingData = getTrackingData(pageData);
  if(!trackingData) {
    return;
  }
  const { eventType, eventData } = trackingData;
  const tracker = await crdl();
  tracker.trackEvent({ eventType, eventData });
}

export async function trackCordialSignUp(eventData) {
  const tracker = await crdl();
  tracker.trackUserSignUp(eventData);
}
