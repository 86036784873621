import React, { useContext, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import AnimateHeight from 'react-animate-height';
import SvgIcon from '../../svg/SvgIcon';
import SearchBar from '../SearchBar';
import Shop from '../../Shop';
import SubmenuCategory from '../submenu/SubmenuCategory';
import TrackableLink from '../../TrackableLink';
import { AnalyticsContext } from '@/context';
import tracking from '@/services/tracking';

let FocusTrap;
if (typeof window !== 'undefined') {
  FocusTrap = require('focus-trap-react');
}

const MobileSubmenu = (props) => {
  const { navConfig, menuToggled, canonicalUrl, toggleNavItem, mobileNavToggleRef } = props;
  const [toggledMenuIndex, setToggledMenuIndex] = useState(null);
  const pageInfo = useContext(AnalyticsContext);

  if (menuToggled !== 'mobile') {
    return null;
  }

  const onToggle = (position) => {
    const eventInfo = {
      item_type: 'button',
      unit_name: 'hidden_mobile',
      unit_type: 'nav',
    };

    if (position.length > 0) {
      // toggling open (also save what is toggled in state, so it can be used for tracking)
      const menuPosition = position[0];
      const item = navConfig.primary[menuPosition].key;
      eventInfo.action_type = 'show';
      eventInfo.action_value = `hidden_${item}`;
      eventInfo.item_name = item;
      eventInfo.position_in_unit = menuPosition + 1; // offset for search bar
      // save what is toggled in state so it can be used for tracking if toggling closed as "position"
      //  will be empty in this case
      setToggledMenuIndex(menuPosition);
    } else {
      // toggling closed
      const item = navConfig.primary[toggledMenuIndex].key;
      eventInfo.action_type = 'hide';
      eventInfo.action_value = `hidden_${item}`;
      eventInfo.item_name = item;
      eventInfo.position_in_unit = toggledMenuIndex + 1; // offset for search bar
      setToggledMenuIndex(null);
    }

    tracking.trackContentAction({
      eventInfo,
      pageInfo,
    });
  };

  const baseMobileSubmenu = (
    <div className="mobile-submenu">
      <button
        aria-label="Close submenu"
        id="mobile-close-button"
        className="nav__submenu-button nav__submenu-button--close button--clear xs-flex"
        ref={mobileNavToggleRef}
        onClick={() => toggleNavItem('mobile')}
        value={menuToggled}
      >
        <SvgIcon
          aria-hidden="true"
          className="nav__submenu-button-icon"
          id='x'
        />
      </button>
      <div className="mobile-submenu__search">
        <SearchBar canonicalUrl={canonicalUrl} display="mobile" />
      </div>
      <Accordion allowZeroExpanded onChange={onToggle}>
        { navConfig.primary.map((submenu, index) => {
          return (
            <AccordionItem
              className="mobile-submenu__item-container"
              key={`submenu-${index}-${submenu.title.substr(0, 15)}`}
              uuid={index}
            >
              <AccordionItemHeading>
                <AccordionItemButton className="mobile-submenu__item">
                  <p className="mobile-submenu__item-title">{ submenu.title }</p>
                  <AccordionItemState>
                    {({ expanded }) => (
                      <SvgIcon
                        aria-hidden={true}
                        className={`mobile-submenu__item-icon ${expanded ? 'mobile-submenu__item-icon--toggled' : ''}`}
                        id="arrow-down"
                      />
                    )}
                  </AccordionItemState>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemState>
                {({ expanded }) => (
                  <AnimateHeight height={expanded ? 'auto' : 0}>
                    <AccordionItemPanel className={`mobile-submenu__item-contents mobile-submenu__item-contents--${submenu.key}`}>
                      {submenu.key !== 'shop' && submenu.categories.map((category, categoryIndex) =>
                        <SubmenuCategory
                          key={categoryIndex}
                          submenuKey={submenu.key}
                          category={category}
                          categoryIndex={categoryIndex}
                        />
                      )}
                      {submenu.key === 'shop' &&
                        <Shop
                          content={submenu}
                          trackingFields={{
                            unit_name: 'hidden_shop',
                            unit_type: 'nav',
                          }}
                          variant="nav"
                        />
                      }
                      {submenu.key === 'recipes' &&
                        <div className="nav__recipes-submit-content">
                          <h2 className="nav__recipes-submit-heading">Submit a recipe to Tasty!</h2>
                          <p>Have a recipe of your own to share?
                            <TrackableLink
                              className="nav__recipes-submit-button button--tasty button--tasty-secondary"
                              linkTracking={{
                                item_name: 'recipe_submission',
                                item_type: 'button',
                                position_in_unit: 6,
                                target_content_id: 'recipe_submission',
                                target_content_type: 'form',
                                unit_name: 'hidden_recipes',
                                unit_type: 'nav'
                              }}
                              openInNewWindow={true}
                              url="/submit-recipe"
                            >
                              Submit your recipe
                            </TrackableLink>
                          </p>
                        </div>
                      }
                    </AccordionItemPanel>
                  </AnimateHeight>
                )}
              </AccordionItemState>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );

  const mobileSubmenu = menuToggled === 'mobile' ?
    <div key='mobile-submenu'>
      <FocusTrap
        focusTrapOptions={{ clickOutsideDeactivates: true, initialFocus: '#mobile-close-button' }}
        key='mobile-submenu'
      >
        {baseMobileSubmenu}
      </FocusTrap>
    </div> : baseMobileSubmenu;

  return (
    <div className="mobile-submenu-container" role="dialog" aria-modal="true" aria-label="mobile submenu">
      {mobileSubmenu}
    </div>
  );
};

export default MobileSubmenu;
