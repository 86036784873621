function ScreenreaderContainer() {
  return (
    <div hidden>
      <span id="new-window-internal">Opens in a new window</span>
      <span id="new-window-external">Opens an external site in a new window</span>
      <span id="external-link">Opens an external site</span>
    </div>
  );
}

export default ScreenreaderContainer;
