import { ABeagleContext } from '@/context';
import tracking from '@/services/tracking';
import { attachClientTimeSpentHandler } from '@/services/tracking/client-event-tracking';
import { trackComscorePageview } from '@/services/tracking/comscore';
import { trackCordialPageView } from '@/services/tracking/cordial';
import { useConsent } from '@buzzfeed/react-components';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { trackPermutivePageView } from '../../services/tracking/permutive/client';
import { getExperimentVariant } from '@buzzfeed/react-components/lib/utils/abeagle';

export default function PageTracking({ children, pageProps }) {
  const { consentValue, isConsentReady } = useConsent('tracking');
  const { consentValue: permutiveConsentValue, isConsentReady: isPermutiveConsentReady } = useConsent('permutive');
  const { experiments } = useContext(ABeagleContext);
  /**
   * page path is url minus host and any query params, can be used to uniquely identify
   *  a true new pageview
   */
  const { analyticsInfo, pagePath } = pageProps;

  /**
   * Performance is not dependent on consent. These events should only need to be invoked
   *  once on initial load, and not on subsequent client side navigation
   */
  useEffect(() => {
    tracking.trackPerformance(analyticsInfo);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Sending events based solely on the pagePath change
   *  pageviews that do NOT require consent (GA, PD, Comscore)
   *  when the pagePath changes, we want to also restart sending the time_spent metrics.
   */
  useEffect(() => {
    tracking.trackAddressability({
      path: window.location.pathname + window.location.search,
      pageInfo: analyticsInfo,
    });
    tracking.trackPageview({
      path: window.location.pathname + window.location.search,
      pageInfo: analyticsInfo,
    });
    trackComscorePageview();
  }, [pagePath]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let time_spent_handler = () => {};
    if (!experiments.stale && experiments.loaded) {
      const variant = getExperimentVariant(experiments, 'DT-516-tasty_ui-time-spent-metrics-test',
        { rejectErrors: false });
      if (variant === 'time_spent_tracked') {
        time_spent_handler = attachClientTimeSpentHandler(analyticsInfo);
      }
    }
    return time_spent_handler;
  }, [pagePath, experiments.loaded]);

  /**
   * Quantcast beancon tracking: requires consent
   */
  useEffect(() => {
    if (isConsentReady && consentValue) {
      // cordial pageview tracking: requires consent
      trackCordialPageView(pageProps);
    }
  }, [isConsentReady, consentValue, pagePath]); // eslint-disable-line react-hooks/exhaustive-deps

  // Permutive
  useEffect(() => {
    const isPermutiveEnabled = experiments.eligible &&
      experiments.eligible['ADSGROUP-442-permutive'] &&
      experiments.eligible['ADSGROUP-442-permutive'].value === 'on';
    if (isPermutiveEnabled && isPermutiveConsentReady && permutiveConsentValue) {
      trackPermutivePageView(pageProps);
    }
  }, [experiments.loaded, isPermutiveConsentReady, permutiveConsentValue, pagePath]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      { children }
    </React.Fragment>
  );
}

PageTracking.propTypes = {
  pageProps: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};
