import { createContext, useContext } from 'react';
import { useJsonStorage } from '../../hooks/useJsonStorage';
import { API_PROXY_URL } from '@/constants';
import { postJSON } from '../../services/api/_base';

/**
 * @typedef {{ recipeId: Number, portions: Number }} StoredMyCartRecipe
 */

/**
 * @typedef {{ recipes: Array<StoredMyCartRecipe> }} StoredMyCart
 */

/**
 * @typedef {('itemsAdded'|'itemsRemoved')} MyCartNotificationType
 */

/**
 * @typedef {{
 *   notificationType: MyCartNotificationType
 *   data: ({ items: Array<Object> } | { itemsRemoved: Number })
 * }} MyCartNotification
 */

/**
 * @typedef {{
 *  totalProducts: number,
 *  addRecipe: function() : Promise<MyCartNotification>,
 *  removeRecipe: function() : Promise<MyCartNotification>,
 *  addMoreProducts: function() : Promise<MyCartNotification>,
 *  updateMoreProducts: function() : Promise<MyCartNotification>,
 *  hasRecipe: function() : Boolean,
 *  resolve: function
 * }} MyCart
 */

/**
 * @type React.Context<MyCartContext>
 */
const MyCartContext = createContext();

export const useMyCart = () => {
  const myCart = useContext(MyCartContext);

  return myCart;
};

export const reduceTotalQuantity = (total, product) => total + product.quantity;

export const reduceTotalPrice = (total, product) => total + product.quantity * product.price;

const fetchResolve = async (body) => {
  const response = await postJSON(`${API_PROXY_URL}/api/proxy/online-grocery/addtobag-recipes`, body);
  let totalProducts = 0;
  if (response.recipes) {
    for (const key in response.recipes) {
      totalProducts += response.recipes[key].products.reduce(reduceTotalQuantity, 0);
    }
  }
  if (!response.moreProducts || !response.moreProducts.length) {
    response.moreProducts = body.moreProducts || [];
  }
  totalProducts += response.sharedProducts.reduce(reduceTotalQuantity, 0);
  totalProducts += response.pantryProducts.reduce(reduceTotalQuantity, 0);
  totalProducts += response.moreProducts.reduce(reduceTotalQuantity, 0);

  return {
    totalProducts,
    ...response,
  };
}

export default {
  Provider(props) {
    /** @type [StoredMyCart, function] */
    const [myCart, setMyCart] = useJsonStorage('myCart');
    const { recipes = [], totalProducts = 0, productExceptions = [], moreProducts = [] } = myCart || {};

    /**
     * @returns {MyCartNotification}
     */
    const addRecipe = async (recipeId, { portions, fulfillmentStoreId }) => {
      if (hasRecipe(recipeId)) {
        return;
      }

      const newRecipe = { recipeId, portions };
      const newRecipes = recipes.concat(newRecipe);
      const body = {
        recipes: newRecipes,
        storeIdentifier: fulfillmentStoreId
      };

      const { totalProducts, recipes: resolvedRecipes, sharedProducts } = await fetchResolve(body);
      const newResolvedRecipe = resolvedRecipes[recipeId];
      const newProducts = [
        ...newResolvedRecipe.products,
        // Looking for shared products that were added for the new recipe
        ...sharedProducts.filter(product => product.recipe_ingredient_ids.find(({ id }) => newResolvedRecipe.ingredientIds.includes(id)))
      ];

      setMyCart({
        ...myCart,
        recipes: newRecipes,
        totalProducts: totalProducts + (myCart?.moreProducts?.length || 0),
        productExceptions: myCart?.productExceptions || [],
      });

      return {
        notificationType: 'itemsAdded',
        data: {
          items: newProducts
        }
      };
    };

    /**
     * @returns {MyCartNotification}
     */
    const addMoreProducts = async (products = []) => {
      let moreProducts = myCart?.moreProducts || [];
      products.forEach((product) => {
        let productExists = false;
        const price = product.price.price;
        product.price = price;
        product.quantity = 1;
        product.strategy = product.in_stock ? 'in_stock' : 'out_of_stock';
        product.image_url = product?.images?.length ? product?.images[0].url : '';
        product.name = product.meta?.title;
        product.brand = product.meta?.brand_name || '';
        product.isAdaptedItem = true;
        // just increase quantity, if the product exists
        moreProducts.forEach(existingProduct => {
          if (existingProduct.id === product.id) {
            const newQuantity = existingProduct.quantity + 1;
            existingProduct.quantity = newQuantity;
            productExists = true;
          }
        });
        // else: add to moreProducts
        if (!productExists) {
          moreProducts.push(product);
        }
      });

      setMyCart({
        ...myCart,
        totalProducts: myCart ? (myCart.totalProducts + products.length) : products.length,
        moreProducts,
      });

      return {
        notificationType: 'itemsAdded',
        data: {
          items: products
        }
      };
    };

    const updateMoreProducts = async ({ newQuantity, ingredientId }) => {
      const totalProducts = myCart.totalProducts - myCart.moreProducts.length + newQuantity;
      const newMoreProducts = [];
      if (newQuantity > 0) {
        myCart.moreProducts.forEach(product => {
          if (product.id === ingredientId) {
            newMoreProducts.push({...product, quantity: newQuantity});
          } else {
            newMoreProducts.push(product);
          }
        });
      } else {
        myCart.moreProducts.forEach(product => {
          if (product.id !== ingredientId) {
            newMoreProducts.push(product);
          }
        });
      }
      setMyCart({
        ...myCart,
        totalProducts,
        moreProducts: newMoreProducts,
      });
    };

    const removeRecipe = async (recipeId, { fulfillmentStoreId, shouldResolve = true }) => {
      const newRecipes = recipes.filter(recipe => recipe.recipeId !== Number(recipeId));
      let totalProducts;
      const moreProductLength = myCart.moreProducts?.length || 0;

      if (!shouldResolve) {
        setMyCart({
          ...myCart,
          recipes: newRecipes,
          totalProducts: newRecipes.length === 0 ? moreProductLength : myCart.totalProducts,
        });
        return;
      }

      if (newRecipes.length > 0) {
        const body = {
          recipes: newRecipes,
          storeIdentifier: fulfillmentStoreId,
        };

        const resolvedBag = await fetchResolve(body);
        totalProducts = resolvedBag.totalProducts + moreProductLength;
      } else {
        totalProducts = moreProductLength;
      }

      setMyCart({
        ...myCart,
        recipes: newRecipes,
        totalProducts,
      });

      return {
        notificationType: 'itemsRemoved',
        data: {
          itemsRemoved: myCart.totalProducts - totalProducts
        }
      };
    };

    const hasRecipe = (recipeId) => {
      return !!recipes.find(r => r.recipeId === recipeId);
    };

    const resolve = async ({ fulfillmentStoreId }) => {
      if (myCart && fulfillmentStoreId && myCart?.recipes?.length) {
        const body = {
          recipes: myCart.recipes || [],
          storeIdentifier: fulfillmentStoreId,
          productExceptions: myCart.productExceptions,
          moreProducts: myCart.moreProducts || [],
        };

        const response = await fetchResolve(body);

        setMyCart({
          ...myCart,
          totalProducts: response.totalProducts,
        });

        return response;
      }
    };

    const addProductException = (productExternalId, newQuantity, substituteIdentifier, recipeIds) => {
      const exception = {
        recipe_ids: recipeIds,
        external_id: productExternalId,
        amount: newQuantity,
        identifier: substituteIdentifier,
      };
      const newProductExceptions = productExceptions.filter(ex => ex.external_id !== productExternalId);

      setMyCart({
        ...myCart,
        productExceptions: [
          ...newProductExceptions,
          exception,
        ]
      });
    };

    const changeRecipePortions = (recipeId, newPortions) => {
      const newProductExceptions = productExceptions.filter(exception => !exception.recipe_ids.includes(recipeId));
      const newRecipes = recipes.filter(recipe => recipe.recipeId !== recipeId);

      setMyCart({
        ...myCart,
        productExceptions: newProductExceptions,
        recipes: [
          ...newRecipes,
          { recipeId, portions: newPortions },
        ],
      });
    };

    const clearMyCart = () => {
      setMyCart({
        recipes: [],
        productExceptions: [],
        totalProducts: 0,
      });
    };

    return <MyCartContext.Provider
      {...props}
      value={{
        addRecipe,
        removeRecipe,
        addMoreProducts,
        hasRecipe,
        recipes,
        resolve,
        addProductException,
        changeRecipePortions,
        clearMyCart,
        totalProducts,
        productExceptions,
        moreProducts,
        updateMoreProducts
      }}
    />;
  }
}
