import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import TastyApi from '@/services/api/tasty';
import SvgIcon from '../../svg/SvgIcon';
import TrackableLink from '../../TrackableLink';
import tracking from '@/services/tracking';
import { AnalyticsContext } from '@/context';
import { withErrorBoundary } from '@buzzfeed/react-components';
import { captureException } from '@sentry/nextjs';

export const SearchBarContents = (props) => {
  const { display, analyticsInfo, canonicalUrl } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [initialTerm, setInitialTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [highlightIndex, setHighlightIndex] = useState(null);

  const textInput = useRef();

  const handleUserKeyPress = (e) => {
    const pageInfo = analyticsInfo;
    switch(e.key) {
      case 'ArrowUp':
        if (highlightIndex > 0) {
          setHighlightIndex(highlightIndex - 1);
          setSearchTerm(suggestions[highlightIndex - 1].display);
        } else if (highlightIndex === null && suggestions.length > 0) {
          setHighlightIndex(suggestions.length - 1);
          setSearchTerm(suggestions[suggestions.length - 1].display);
        } else if (highlightIndex === 0) {
          /* removes styling on suggestions */
          setHighlightIndex(null);
          setSearchTerm(initialTerm);
        }
        break;
      case 'ArrowDown':
        if (highlightIndex === null && suggestions.length > 0) {
          setHighlightIndex(0);
          setSearchTerm(suggestions[0].display);
        } else if (highlightIndex >= 0 && (highlightIndex < suggestions.length - 1)) {
          setHighlightIndex(highlightIndex + 1);
          setSearchTerm(suggestions[highlightIndex + 1].display);
        } else {
          setHighlightIndex(null);
          setSearchTerm(initialTerm);
        }
        break;
      case 'Escape':
        setSuggestions([]);
        setSearchTerm(initialTerm);
        setHighlightIndex(null);
        break;
      case 'Enter':
        if (searchTerm !== '') {
          if (highlightIndex !== null && highlightIndex >= 0) {
            // CET & GA
            tracking.trackInternalLink({
              eventInfo: {
                item_name: searchTerm,
                item_type: 'text',
                position_in_subunit: highlightIndex,
                position_in_unit: display === 'desktop' ? 4 : 0,
                subunit_name: 'suggestions',
                subunit_type: 'component',
                target_content_id: 'search',
                target_content_type: 'feed',
                unit_type: 'nav',
                unit_name: display === 'desktop' ? 'main' : 'hidden_mobile',
              },
              pageInfo,
            });
          } else {
            // CET & GA
            tracking.trackContentAction({
              eventInfo: {
                action_type: 'search',
                action_value: searchTerm,
                item_name: 'search',
                item_type: 'submission',
                position_in_unit: display === 'desktop' ? 4 : 0,
                unit_name: display === 'desktop' ? 'main' : 'hidden_mobile',
                unit_type: 'nav',
              },
              pageInfo,
            });
          }
          window.location.href = canonicalUrl + '/search?q=' + encodeURIComponent(searchTerm);
          e.preventDefault();
        }
        break;
      default:
        /* Assume all other cases are printable characters or manipulation keys handled by onChange */
        break;
    }
  };

  const clearInput = () => {
    setSearchTerm('');
    setInitialTerm('');
    setSuggestions([]);
    if (textInput && textInput.current) {
      textInput.current.focus();
    }
  };

  const getSuggestions = async(newSearchTerm) => {
    // swallow any thrown errors from the fetching of suggestions, as we want
    //  the page to continue uninterrupted, and not having suggestions is not
    //  considered breaking.
    try {
      const suggestionsResponse = await TastyApi.fetchSuggestions(newSearchTerm);
      setSuggestions(suggestionsResponse);
    } catch (e) {} // eslint-disable-line no-empty
  };

  const throttledGetSuggestions = useCallback( // eslint-disable-line react-hooks/exhaustive-deps
    throttle((newValue) => getSuggestions(newValue), 1000),
    []
  );

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    setInitialTerm(event.target.value);
    throttledGetSuggestions(event.target.value);
  };

  const handleFocus = () => {
    const pageInfo = analyticsInfo;
    tracking.trackContentAction({
      eventInfo: {
        action_type: 'search',
        action_value: 'focus',
        item_name: 'search',
        item_type: 'input',
        position_in_unit: display === 'desktop' ? 4 : 0,
        unit_name: display === 'desktop' ? 'main' : 'hidden_mobile',
        unit_type: 'nav',
      },
      pageInfo,
    });
  };

  /**
   * There are browser differences about when the default form submit vs key press
   *  events get called when a user presses "Enter".  As a result, on some browsers
   *  (like Safari), form submit gets called first, and the keypress event is not reached.
   *  Therefore need to prevent default form submit behavior, so that the key press event
   *  always gets handled.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const formatSuggestions = () => {
    // suggestion results from tasty_ui API proxy limit to max 4 results - is safe to loop through all results
    return suggestions.map((suggestion, index) => {
      const formattedSearchTerm = initialTerm.toLowerCase();
      const splitDisplay = suggestion.display.split(formattedSearchTerm);
      const htmlArray = [];

      for (let i = 0; i < splitDisplay.length; i++) {
        htmlArray.push(<span key={i}>{splitDisplay[i]}</span>);
        if (i < splitDisplay.length - 1) {
          htmlArray.push(
            <span className="extra-bold" key={`bold-${i}`}>
              {formattedSearchTerm}
            </span>
          );
        }
      }
      return (
        <li aria-selected={highlightIndex === index}
          id={`${suggestion.display}_${index}`}
          key={`${suggestion.display}_${index}`}
          role="option">
          <TrackableLink
            className={`search-bar__autosuggest-link ${highlightIndex === index ? 'highlight' : ''}`}
            linkTracking={{
              item_name: suggestion.display,
              item_type: 'text',
              position_in_unit: display === 'desktop' ? 4 : 0,
              position_in_subunit: index,
              subunit_name: 'suggestions',
              subunit_type: 'component',
              target_content_id: 'search',
              target_content_type: 'feed',
              unit_name: display === 'desktop' ? 'main' : 'hidden_mobile',
              unit_type: 'nav'
            }}
            tabIndex="-1"
            url={`${canonicalUrl}/search?q=${suggestion.display}`}
          >
            {htmlArray}
          </TrackableLink>
        </li>
      );
    });
  };

  const formatted_suggestions = formatSuggestions();

  return (
    <form
      className="search-bar-form"
      autoComplete="off"
      id={`search-form-${display}`}
      onSubmit={handleSubmit}
    >
      <div id="instructions" className="screenreader-only">Begin typing to search, use arrow keys to navigate, press Enter to select and submit search query.</div>
      <div className="search-bar">
        <SvgIcon
          aria-hidden="true"
          className="search-bar__search-icon"
          id="search-icon"/>
        <label id="search-input" className="screenreader-only" htmlFor={`search-${display}`}>Search for recipes</label>
        <input
          aria-activedescendant={suggestions.length > 0 && searchTerm && searchTerm.length > 0 ? `${searchTerm}_${highlightIndex}` : ''}
          aria-autocomplete="list"
          aria-controls="suggestions"
          aria-describedby="instructions"
          aria-haspopup="true"
          aria-expanded={suggestions.length > 0 && searchTerm && searchTerm.length > 0}
          className="search-bar__input"
          id={`search-${display}`}
          list="suggestions"
          name="search"
          onChange={handleChange}
          onKeyUp={handleUserKeyPress}
          onFocus={handleFocus}
          placeholder="Search Tasty"
          ref={textInput}
          type="text"
          value={searchTerm}
        />
        <button
          aria-label="Clear Search"
          className={`search-bar__clear-search-button button--clear ${searchTerm ? '' : 'search-bar__clear-search-button--hide'}`}
          onClick={clearInput}
          type="button"
        >
          <SvgIcon id="x"/>
        </button>
      </div>
      { suggestions.length > 0 && searchTerm && searchTerm.length > 0 &&
        <div className="search-bar__autosuggest">
          <div id="suggestions">
            <ul aria-labelledby="search-input" className="list-unstyled" role="listbox">
              {formatted_suggestions}
            </ul>
          </div>
        </div>
      }
      <div className="aria-results screenreader-only" aria-live="assertive">{suggestions.length > 0 && searchTerm && searchTerm.length > 0 ? suggestions.length + ' results found' : !searchTerm ? '' : 'No results found'}</div>
    </form>
  );
};

SearchBarContents.propTypes = {
  analyticsInfo: PropTypes.object,
  canonicalUrl: PropTypes.string.isRequired,
};


const SearchBar = (props) => {
  return (
    <AnalyticsContext.Consumer>
      {(analyticsContext) => (
        <SearchBarContents {...props} analyticsInfo={analyticsContext}/>
      )}
    </AnalyticsContext.Consumer>
  );
};

SearchBar.propTypes = {
  canonicalUrl: PropTypes.string.isRequired,
};

export default withErrorBoundary(SearchBar, {
  onError: captureException
});


